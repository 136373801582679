// Functional imports
import { useState, useEffect } from 'react';
import { calculateLableLength, LABEL_CHAR_MAX_LIMIT } from '../../../Utility/Utility';
import { isEmpty } from '../../../Utility/Utility';

// Styled Imports
import styles from './InputBox.module.css';

function InputBox(props){

    const [ enteredValue, setEnteredValue ] = useState(props.value);
    const [ isDisabled, disabledClassName ] = [ !!props.disabled, styles['disabled'] ];

    useEffect(() => {
        setEnteredValue( prev => props.value );
    }, [props.value]);

    function handelOnChange(event){
        const value = event.target.value;
        setEnteredValue(value);
        props.onChange && props.onChange(event);
    }

    function fileUnselectHandler(event){
        setEnteredValue('');
        props.fileUnselectHandler && props.fileUnselectHandler(event);
    }

    let labelClassNames = (!!props.className)? `${props.className} ` : '';
    let inputClassNames = (!!props.className)? `${props.className} ` : '';

    if( isDisabled ){
        labelClassNames += `${disabledClassName} `;
        inputClassNames += `${disabledClassName} `;
    }

    if( props.required ){
        labelClassNames += `${styles['required']} `;
    }

    if( calculateLableLength(props) > LABEL_CHAR_MAX_LIMIT ){
        throw new Error(`"${props.label} ${props.labelSpan} - ${calculateLableLength(props)}" Label length greater than ${LABEL_CHAR_MAX_LIMIT}`)
    }

    if(props.type === 'file' && !isEmpty(enteredValue)){
        const value = (enteredValue.includes('fakepath'))? enteredValue.split('\\')[2] : enteredValue;

        return (
            <div className={styles[`input-container`]}>
                { 
                    !props.hideLabel && 
                    <label 
                        className={labelClassNames} 
                        htmlFor={props.id}
                    >
                        {props.label}
                        {props.labelSpan && <span>{props.labelSpan}</span>}
                    </label>
                }

                <input 
                    className={inputClassNames}
                    type='text' 
                    id={props.id} 
                    value={value}
                    name={props.name}
                    disabled={true}
                    required={props.required}
                />
                <span className={styles['remove-icon']}>
                    <i className="lni lni-cross-circle" data-name={props.name} onClick={fileUnselectHandler}></i>
                </span>   
            </div>
        );
    }

    return (
        <div className={styles[`input-container`]}>
            { 
                !props.hideLabel && 
                <label 
                    className={labelClassNames} 
                    htmlFor={props.id}
                >
                    {props.label}
                    {props.labelSpan && <span>{props.labelSpan}</span>}
                </label>
            }
            
            <input 
                className={inputClassNames}
                type={props.type} 
                id={props.id} 
                value={enteredValue}
                name={props.name}
                onChange={handelOnChange}
                disabled={isDisabled}
                required={props.required}
                onClick={props.onClick}
                placeholder={props.placeholder}
                ref={props.reference}
                onBlur={props.onBlur}
                accept={props.accept}
                multiple={!!props.multiple}
            />
        </div>
    );
}

function getValueFromInputBoxByName(event, name){
    if( event?.target ){
        return event.target.querySelector(`[name='${name}']`)?.value?.trim()
    } 

    return event.querySelector(`[name='${name}']`)?.value?.trim();
}

export default InputBox;
export { getValueFromInputBoxByName };