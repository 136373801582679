// Functional Imports
import React from 'react';
import ReactDOM  from 'react-dom';

// Styles Import 
import styles from './Loader.module.css';

function Backdrop(){
    return <div className={styles.backdrop}></div>;
}


function LoaderHTML( props ){
    return ( 
        <div className={styles['loader-container']} >
            <span className={`${styles['loader']} ${styles[props.type]}`}></span> 
        </div>
    );
}

function Loader( props ){

    const loaderType = ( props.loaderType && styles[props.loaderType] )? props.loaderType : 'logo-loader';

    const loader = ReactDOM.createPortal((<>
            <Backdrop />
            <LoaderHTML type={loaderType} />
        </>), 
        document.getElementById('loading') 
    );

    return (
        props.display && loader
    );
}

export default Loader;