// Components Import
import Separator from '../../UI/Separator/Separator';

// Styles and Assets Import 
import styles from './BFHeader.module.css';
import concordeLogo from '../../../Assets/Images/concorde-logo.png';

function BFHeader( props ){

    return (
        <header className={styles.header}>
            <div className={styles['logo-container']}>
                <img src={concordeLogo} alt="Concorde Logo"/>
            </div>
            <Separator type='no-circle' />
            <div className={styles['current-view-info']}>
                <p>{props.currentViewInfo}</p>
            </div>
        </header>
    );
}

export default BFHeader;