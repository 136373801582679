export function errorModalReducer( state, action ){
    if( action.type === 'NEW_ERROR' )
        return { display: true, title: action.title, message: action.message, className: [] };

    if( action.type === 'CLOSE_MODAL' )
        return { modalDisplay: false, title: '', message: '', className: [...state.className] }
}

export function addressReducer( state, action ){
    const processedAddress = { ...state };

    if( action.type === 'PERMANENT_ADDRESS_TYPE' )
        processedAddress.coApp_permanentAddressType = action.value;

    switch (processedAddress.coApp_permanentAddressType) {
        case 'Same As Present Address':
            processedAddress.coApp_permanentAddress = processedAddress.coApp_presentAddress;
            processedAddress.coApplicantPermanentAddressDisabled = true;
        break;

        case 'Different':
            processedAddress.coApp_permanentAddress = '';
            processedAddress.coApplicantPermanentAddressDisabled = false;
        break;
    
        default:
            processedAddress.coApp_permanentAddress = '';
            processedAddress.coApplicantPermanentAddressDisabled = true;
        break;
    }

    return processedAddress;
}
