// Functional Imports
import { useReducer, useState } from 'react';
import { MAX_UPLOAD_SIZE, IMG_DOC_ACCEPTED_FILE_FORMAT, isEmpty } from '../../../../Utility/Utility';

// Components Import
import InputBox from '../../../UI/InputBox/InputBox';
import PopupListBox, { fetchSelectedOption } from '../../../UI/PopupListBox/PopupListBox';
import Button from '../../../UI/Button/Button';
import Modal from '../../../UI/ShowModal/ShowModal';

// Styled Imports
import formUtilityStyles from '../../BookingForm.module.css';
const INPUTS_FILE_FORMATS = { 
    aadharCopyLocation:         IMG_DOC_ACCEPTED_FILE_FORMAT, 
    panCopyLocation:            IMG_DOC_ACCEPTED_FILE_FORMAT, 
    photoOfApplicantLocation:   IMG_DOC_ACCEPTED_FILE_FORMAT 
};
const INPUTS_FILE_DATA_STORE = { 
    aadharCopyLocation:         'AADHAR_COPY', 
    panCopyLocation:            'PAN_COPY', 
    photoOfApplicantLocation:   'APPLICANT_PHOTO' 
};

const { 'form-actions' : formActionsCN, 'wrong-input': wrongInput } = formUtilityStyles;
const CO_APPLICANT_OPTIONS = ['Select One', 'Yes', 'No'];

function errorModalReducer( state, action ){
    if( action.type === 'NEW_ERROR' ){
        return { display: true, title: action.title, message: action.message, className: [action.name] };
    }

    if( action.type === 'CLOSE_MODAL' ){
        return { modalDisplay: false, title: '', message: '', className: [...state.className]}
    }
}

function BFPage10(props) {

    const [ errorState, dispatchErrorState ] = useReducer(
        errorModalReducer,  { display: false, title: '', message: '', className: [] }
    );
    const [ submitButtonState, setSubmitButtonState ] = useState('Submit');
    const [userFileStore, setUserFileStore] = useState({ AADHAR_COPY: undefined, PAN_COPY: undefined, APPLICANT_PHOTO: undefined });
    const [ inputFileKeys, setInputFileKeys ] = useState({ aadharCopyLocation: 100, panCopyLocation: 200, photoOfApplicantLocation: 300 }); // Created to reset user selected input

    useState(() => {
        const selectedValue = props.filledValues.wantToAdd_CoApp;
        
        if( selectedValue === 'Yes' ){
            setSubmitButtonState('Next');
            return;
        }

        setSubmitButtonState('Submit');
    }, [])

    function handelViewChange(eventOrState){ 
        const viewState = eventOrState?.target?.getAttribute('data-change-state-to') || eventOrState;
        props.changeView(viewState);
    }

    async function formSubmitHandler(event){
        event.preventDefault();

        const wantToAdd_CoApp = fetchSelectedOption(event, 'wantToAdd_CoApp').selectedValue;

        if( wantToAdd_CoApp === 'Select One' ){
            dispatchErrorState({ type: 'NEW_ERROR', name: 'wantToAdd_CoApp', title: 'Invalid Input!', message: 'Please select a valid option' });    
            return;  
        }

        if(isEmpty(props.filledValues.aadharCopyLocation) && isEmpty(userFileStore.AADHAR_COPY)){
            dispatchErrorState({ type: 'NEW_ERROR', name: 'aadharCopyLocation', title: 'Invalid Input!', message: 'Please select a valid aadhar copy' });    
            return;
        }

        if(isEmpty(props.filledValues.panCopyLocation) && isEmpty(userFileStore.PAN_COPY)){
            dispatchErrorState({ type: 'NEW_ERROR', name: 'panCopyLocation', title: 'Invalid Input!', message: 'Please select a valid pan copy' });    
            return;
        }

        if(isEmpty(props.filledValues.photoOfApplicantLocation) && isEmpty(userFileStore.APPLICANT_PHOTO)){
            dispatchErrorState({ type: 'NEW_ERROR', name: 'photoOfApplicantLocation', title: 'Invalid Input!', message: 'Please select a valid applicant photo' });    
            return;
        }

        const payLoad = { wantToAdd_CoApp, submittedByUser: wantToAdd_CoApp !== 'Yes', DOCS_TO_UPLOAD: userFileStore };

        if(await props.formSubmitHandler(payLoad)){
            if(wantToAdd_CoApp === 'Yes') return handelViewChange('NEXT');
            props.refreshData();
        } 
    }

    function handelCoApplicantChange(event){
        const selectedValue = event.target.value;

        if( selectedValue === 'Yes' ){
            setSubmitButtonState('Next');
            return;
        }

        setSubmitButtonState('Submit');
    }

    function handelFileSelection(event){
        const files = event.target.files;
        const name = event.target.name;

        if( !(name in inputFileKeys) ){
            setInputFileKeys( prevKeys => ({ aadharCopyLocation: ++prevKeys.aadharCopyLocation, panCopyLocation: ++prevKeys.panCopyLocation, photoOfApplicantLocation: ++prevKeys.photoOfApplicantLocation }) ) 
            dispatchErrorState({ type: 'NEW_ERROR', title: 'Code Altered Alert!', message: 'HTML element code has been altered! To fix this please refresh the page.' });
            return;
        }

        if( files.length > 1 ){
            setInputFileKeys( prevKeys => ({ ...prevKeys, [name]: prevKeys[name] + 1 }) ) 
            dispatchErrorState({ type: 'NEW_ERROR', title: 'Invalid Input!', message: 'Only one file must be selected!', name });
            return;
        }

        if( !INPUTS_FILE_FORMATS[name].includes(files[0].type) ){
            setInputFileKeys( prevKeys => ({ ...prevKeys, [name]: prevKeys[name] + 1 }) ) 
            dispatchErrorState({ type: 'NEW_ERROR', title: 'Invalid Input!', message: 'Selected file must be of type ' + INPUTS_FILE_FORMATS[name].join(', '), name });
            return;
        }

        if( Math.round( files[0].size / 1024 ) > MAX_UPLOAD_SIZE ){
            setInputFileKeys( prevKeys => ({ ...prevKeys, [name]: prevKeys[name] + 1 }) ) 
            dispatchErrorState({ type: 'NEW_ERROR', title: 'Exceeded Maximum File Size!', message: `Please make sure the file size is not more than ${MAX_UPLOAD_SIZE / 1024} MB`, name });
            return;
        }

        props.setDisplayLoader(true);

        const reader = new FileReader();
        reader.readAsDataURL(files[0]);

        reader.onload = async () => {
            const data = reader.result.split(',')[1];

            const fileInfo = {
                fileName: files[0].name,
                fileType: files[0].type,
                data
            };
    
            setUserFileStore(prev => ({ ...prev, [INPUTS_FILE_DATA_STORE[name]]: fileInfo }));
            props.setDisplayLoader(false);
        } 

        reader.onerror = async () => {
            setInputFileKeys( prevKeys => ({ ...prevKeys, name: prevKeys[name] + 1 }) )  
            dispatchErrorState({ type: 'NEW_ERROR', title: 'Process failed!', message: files[0].name + ' processing failed! Try resubmitting', name });
            props.setDisplayLoader(false);
        }
    }

    function closeModal(){
        dispatchErrorState({ type: 'CLOSE_MODAL'} );
    }

    return (
        <>
            <form onSubmit={formSubmitHandler}>
            
                <InputBox 
                    key={inputFileKeys.aadharCopyLocation}
                    className={`${errorState.className.includes('aadharCopyLocation')? wrongInput : ''}`}
                    label='Aadhar Copy'
                    type='file' 
                    name='aadharCopyLocation'
                    id='aadharCopyLocation'
                    accept={INPUTS_FILE_FORMATS.aadharCopyLocation.toString()}
                    onChange={handelFileSelection}
                    value={props.filledValues.aadharCopyLocation}
                    fileUnselectHandler={props.userFileUnselectHandler}
                    required
                />

                <InputBox 
                    key={inputFileKeys.panCopyLocation}
                    className={`${errorState.className.includes('panCopyLocation')? wrongInput : ''}`}
                    label='Pan Copy'
                    type='file' 
                    name='panCopyLocation'
                    id='panCopyLocation'
                    accept={INPUTS_FILE_FORMATS.panCopyLocation.toString()}
                    onChange={handelFileSelection}
                    value={props.filledValues.panCopyLocation}
                    fileUnselectHandler={props.userFileUnselectHandler}
                    required
                />

                <InputBox 
                    key={inputFileKeys.photoOfApplicantLocation}
                    className={`${errorState.className.includes('photoOfApplicantLocation')? wrongInput : ''}`}
                    label='Photo Of Applicant'
                    type='file' 
                    name='photoOfApplicantLocation'
                    id='photoOfApplicantLocation'
                    accept={INPUTS_FILE_FORMATS.photoOfApplicantLocation.toString()}
                    onChange={handelFileSelection}
                    value={props.filledValues.photoOfApplicantLocation}
                    fileUnselectHandler={props.userFileUnselectHandler}
                    required
                />

                <PopupListBox 
                    className={errorState.className.includes('wantToAdd_CoApp') && wrongInput}
                    label='Want To Add A Co-Applicant ?'
                    name='wantToAdd_CoApp'
                    popupListData={CO_APPLICANT_OPTIONS}
                    defaultOption={props.filledValues.wantToAdd_CoApp}
                    onChange={handelCoApplicantChange}
                    required
                /> 

                <div className={formActionsCN}>
                    <Button
                        type='button'
                        className='negative'
                        onClick={handelViewChange}
                        dataAttributes={{'data-change-state-to': 'PREVIOUS'}}
                    >
                        <i className="lni lni-angle-double-left" data-change-state-to='PREVIOUS'></i>
                        Previous
                    </Button>
                    <Button
                        type='submit'
                        className='default'
                    >
                        {submitButtonState}
                        
                        {submitButtonState === 'Submit' && <i className="lni lni-save"></i>}
                        {submitButtonState !== 'Submit' && <i className="lni lni-angle-double-right" ></i>}
                    </Button>
                </div>
            </form>
            {errorState.display && <Modal title={errorState.title} message={errorState.message} closeModal={closeModal}/>}
        </>
    );
}

export default BFPage10;