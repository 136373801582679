// Styles Import
import './Button.css';

function Button( props ){
    return (
        <button
            type={props.type}
            className={`btn ${props.className} ${(props.disabled)? 'disabled' : ''}`}
            onClick={props.onClick}
            {...props.dataAttributes}
            disabled={props.disabled}
            style={props.style}
        >
            {props.children}
        </button>
    );
}

export default Button;