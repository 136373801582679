// Functional Imports
import { useReducer, useState } from 'react';
import { IMG_DOC_ACCEPTED_FILE_FORMAT, isEmpty, MAX_UPLOAD_SIZE } from '../../../../Utility/Utility';

// Components Import
import AddressInput, { getAddressDataFromFormByName } from '../../../UI/AddressInput/AddressInput';
import InputBox from '../../../UI/InputBox/InputBox';
import Button from '../../../UI/Button/Button';
import Modal from '../../../UI/ShowModal/ShowModal';

// Styled Imports
import formUtilityStyles from '../../BookingForm.module.css';


const { 'form-actions' : formActionsCN, 'wrong-input': wrongInput } = formUtilityStyles;


function BFPage4(props) {
    const [ errorState, dispatchErrorState ] = useReducer( errorModalReducer,  { display: false, title: '', message: '', className: [] } );
    const [ coApplicantPhotoKey, setcoApplicantPhotoKey ] = useState(0); // Created to nullify user selected input
    const [userFileStore, setUserFileStore] = useState({ CO_APPLICANT_PHOTO: null });

    function handelViewChange(eventOrState){ 
        const viewState = eventOrState?.target?.getAttribute('data-change-state-to') || eventOrState;
        props.changeView(viewState);
    }


    async function formSubmitHandler(event){
        event.preventDefault();

        const coApp_presentAddress = getAddressDataFromFormByName(event, 'coApp_presentAddress');
        
        if( isEmpty( coApp_presentAddress.addressLine1 ) ){
            dispatchErrorState({ type: 'NEW_ERROR', title: 'Invalid Input!', message: 'Please enter a valid line1 address' });
            return; 
        }

        if( isEmpty( coApp_presentAddress.country ) ){
            dispatchErrorState({ type: 'NEW_ERROR', title: 'Invalid Input!', message: 'Please enter a valid country' });
            return; 
        }

        if( isEmpty( coApp_presentAddress.state ) ){
            dispatchErrorState({ type: 'NEW_ERROR', title: 'Invalid Input!', message: 'Please enter a valid state' });
            return; 
        }

        if( isEmpty( coApp_presentAddress.city ) ){
            dispatchErrorState({ type: 'NEW_ERROR', title: 'Invalid Input!', message: 'Please enter a valid city' });
            return; 
        }

        if( isEmpty( coApp_presentAddress.pincode ) ){
            dispatchErrorState({ type: 'NEW_ERROR', title: 'Invalid Input!', message: 'Please enter a valid pincode' });
            return; 
        }

        if(isEmpty(props.filledValues.coApp_photoOfApplicantLocation) && isEmpty(userFileStore.CO_APPLICANT_PHOTO)){
            dispatchErrorState({ type: 'NEW_ERROR', title: 'Invalid Input!', message: 'Please select a valid co-applicant photo', className: ['coApp_photoOfApplicantLocation'] });
            return;
        }

        const payLoad = { coApp_presentAddress, DOCS_TO_UPLOAD: userFileStore };

        if(await props.formSubmitHandler(payLoad)) handelViewChange('NEXT')
    }

    function handelFileSelection(event){
        const files = event.target.files;
        const name = event.target.name;

        if( files.length > 1 ){
            setcoApplicantPhotoKey( prevKey => ++prevKey ) 
            dispatchErrorState({ type: 'NEW_ERROR', title: 'Invalid Input!', message: 'Only one file must be selected!', name });
            return;
        }

        if( !(IMG_DOC_ACCEPTED_FILE_FORMAT.includes( files[0].type )) ){
            setcoApplicantPhotoKey( prevKey => ++prevKey ) 
            dispatchErrorState({ type: 'NEW_ERROR', title: 'Invalid Input!', message: 'Selected file must be of type Image', name });
            return;
        }

        if( Math.round(files[0].size) <= 0 ){
            setcoApplicantPhotoKey( prevKey => ++prevKey ) 
            dispatchErrorState({ type: 'NEW_ERROR', title: 'File Size Too Short!', message: `Please make sure the proper file has been selected.`, name });
            return;
        }

        if( Math.round(files[0].size / 1024) > MAX_UPLOAD_SIZE ){
            setcoApplicantPhotoKey( prevKey => ++prevKey ) 
            dispatchErrorState({ type: 'NEW_ERROR', title: 'Exceeded Maximum File Size!', message: `Please make sure the file size is not more than ${MAX_UPLOAD_SIZE / 1024} MB`, name });
            return;
        }

        props.setDisplayLoader(true);

        const reader = new FileReader();
        reader.readAsDataURL(files[0]);

        reader.onload = async () => {
            const data = reader.result.split(',')[1];

            const fileInfo = {
                fileName: files[0].name,
                fileType: files[0].type,
                data
            };
    
            setUserFileStore({ CO_APPLICANT_PHOTO: fileInfo });
            props.setDisplayLoader(false);
        } 

        reader.onerror = async () => {
            setcoApplicantPhotoKey( prevKey => ++prevKey );
            dispatchErrorState({ type: 'NEW_ERROR', title: 'Process failed!', message: files[0].name + ' processing failed! Try resubmitting', className: ['coApp_photoOfApplicantLocation'] });
            props.setDisplayLoader(false);
        }
    }

    function closeModal(){
        dispatchErrorState({ type: 'CLOSE_MODAL'} );
    }

    return (
        <>
            <form onSubmit={formSubmitHandler}>

                <InputBox 
                    key={coApplicantPhotoKey}
                    className={`${errorState.className.includes('coApp_photoOfApplicantLocation')? wrongInput : ''}`}
                    label='Co-Applicant Photo'
                    type='file' 
                    name='coApp_photoOfApplicantLocation'
                    id='coApp_photoOfApplicantLocation'
                    accept={IMG_DOC_ACCEPTED_FILE_FORMAT.toString()}
                    onChange={handelFileSelection}
                    value={props.filledValues.coApp_photoOfApplicantLocation}
                    required
                />

                <AddressInput
                    label='Co-Applicant Present Address'
                    name='coApp_presentAddress'
                    id='coApp_presentAddress'
                    value={props.filledValues.coApp_presentAddress}
                    required
                />

                <div className={formActionsCN}>
                    <Button
                        type='button'
                        className='negative'
                        onClick={handelViewChange}
                        dataAttributes={{'data-change-state-to': 'PREVIOUS'}}
                    >
                        <i className="lni lni-angle-double-left" data-change-state-to='PREVIOUS'></i>
                        Previous
                    </Button>
                    <Button
                        type='submit'
                        className='default'
                    >
                        Next
                        <i className="lni lni-angle-double-right" ></i>
                    </Button>
                </div>
            </form>
            {errorState.display && <Modal title={errorState.title} message={errorState.message} closeModal={closeModal}/>}
        </>
    );
}

export function errorModalReducer( state, action ){
    if( action.type === 'NEW_ERROR' ){
        return { display: true, title: action.title, message: action.message, className: [action.name] };
    }

    if( action.type === 'CLOSE_MODAL' ){
        return { modalDisplay: false, title: '', message: '', className: [...state.className] }
    }
}


export default BFPage4;