// Functional Imports
import { useContext, useState } from 'react';
import ErrorModalContext from '../../../../context/error-modal-context/error-modal-context';
import { MAX_UPLOAD_SIZE, IMG_DOC_ACCEPTED_FILE_FORMAT, isEmpty } from '../../../../Utility/Utility';

// Components Import
import InputBox, { getValueFromInputBoxByName } from '../../../UI/InputBox/InputBox';
import Button from '../../../UI/Button/Button';
import PopupListBox, { fetchSelectedOption } from '../../../UI/PopupListBox/PopupListBox';

// Styled Imports
import formUtilityStyles from '../../BookingForm.module.css';


const { 'form-actions' : formActionsCN, 'wrong-input': wrongInput } = formUtilityStyles;
const PAYMENT_TYPE = ['Select One', 'UPI', 'Cheque', 'Online Banking', 'Cash Payment'];


function BFPage9(props) {
    const errorModalCtx = useContext(ErrorModalContext);
    const [paymentCopyKey, setPaymentCopyKey] = useState(0); // Created to nullify user selected input
    const [userFileStore, setUserFileStore] = useState({ PAYMENT_COPY: null });

    function handelViewChange(eventOrState){ 
        const viewState = eventOrState?.target?.getAttribute('data-change-state-to') || eventOrState;
        props.changeView(viewState);
    }

    async function formSubmitHandler(event){
        event.preventDefault();

        const bookingAmountPaid  = getValueFromInputBoxByName(event, 'bookingAmountPaid');
        const aadharNumber  = getValueFromInputBoxByName(event, 'aadharNumber');
        const PANNumber  = getValueFromInputBoxByName(event, 'PANNumber');
        const paymentType = fetchSelectedOption(event, 'paymentType').selectedValue;

        if( paymentType === 'Select One' ){
            errorModalCtx.showError('Invalid Input!', 'Please select a payment type.', ['paymentType']);
            return;
        }

        if( String(bookingAmountPaid).length < 1 ){
            errorModalCtx.showError('Invalid Input!', 'Please enter a valid amount.', ['bookingAmountPaid'] );
            return;
        }

        if( aadharNumber.length < 1 ){
            errorModalCtx.showError('Invalid Input!', 'Please enter a valid aadhar number.', ['aadharNumber'] );
            return;
        }

        if( PANNumber.length < 1 ){
            errorModalCtx.showError('Invalid Input!', 'Please enter a valid PAN number.', ['PANNumber'] );
            return;
        }

        if(isEmpty(props.filledValues.paymentCopyLocation) && isEmpty(userFileStore.PAYMENT_COPY)){
            errorModalCtx.showError('Invalid Input!', 'Please select a valid payment copy', ['paymentCopyLocation'] );
            return;
        }

        const payLoad = { paymentType, bookingAmountPaid, aadharNumber, PANNumber, DOCS_TO_UPLOAD: userFileStore };

        if(await props.formSubmitHandler(payLoad)) handelViewChange('NEXT')
    }

    function handelFileSelection(event){
        const files = event.target.files;
        const name = event.target.name;

        if( files.length > 1 ){
            setPaymentCopyKey( prevKey => ++prevKey ) 
            errorModalCtx.showError('Invalid Input!', 'Only one file must be selected!', [name]);
            return;
        }

        if( !IMG_DOC_ACCEPTED_FILE_FORMAT.includes(files[0].type) ){
            setPaymentCopyKey( prevKey => ++prevKey ) 
            errorModalCtx.showError('Invalid Input!', 'Selected file must be of type ' + IMG_DOC_ACCEPTED_FILE_FORMAT.join(', '), [name]);
            return;
        }

        if( Math.round(files[0].size) <= 0 ){
            setPaymentCopyKey( prevKey => ++prevKey ) 
            errorModalCtx.showError('File Size Too Short!', `Please make sure the proper file has been selected.`, [name]);
            return;
        }

        if( Math.round(files[0].size / 1024) > MAX_UPLOAD_SIZE ){
            setPaymentCopyKey( prevKey => ++prevKey ) 
            errorModalCtx.showError('Exceeded Maximum File Size!', `Please make sure the file size is not more than ${MAX_UPLOAD_SIZE / 1024} MB`, [name]);
            return;
        }

        props.setDisplayLoader(true);

        const reader = new FileReader();
        reader.readAsDataURL(files[0]);

        reader.onload = async () => {
            const data = reader.result.split(',')[1];

            const fileInfo = {
                fileName: files[0].name,
                fileType: files[0].type,
                data
            };
    
            setUserFileStore({ PAYMENT_COPY: fileInfo });
            props.setDisplayLoader(false);
        } 

        reader.onerror = async () => {
            setPaymentCopyKey( prevKey => ++prevKey ) 
            errorModalCtx.showError('Process failed!', files[0].name + ' processing failed! Try resubmitting', [name]);
            props.setDisplayLoader(false);
        }
    }

    return (
        <>
            <form onSubmit={formSubmitHandler}>
                <PopupListBox
                    className={`${errorModalCtx.errorFromComp.includes('paymentType')? wrongInput : ''}`}
                    label='Payment Type'
                    name='paymentType'
                    popupListData={PAYMENT_TYPE}
                    defaultOption={props.filledValues.paymentType}
                    required
                />

                <InputBox 
                    className={`${errorModalCtx.errorFromComp.includes('bookingAmountPaid')? wrongInput : ''}`}
                    label='Booking Amount Paid'
                    type='number' 
                    name='bookingAmountPaid'
                    id='bookingAmountPaid'
                    value={props.filledValues.bookingAmountPaid}
                    required
                />
                
                <InputBox 
                    className={`${errorModalCtx.errorFromComp.includes('aadharNumber')? wrongInput : ''}`}
                    label='Aadhar Number'
                    type='text' 
                    name='aadharNumber'
                    id='aadharNumber'
                    value={props.filledValues.aadharNumber}
                    required
                />
                
                <InputBox 
                    className={`${errorModalCtx.errorFromComp.includes('PANNumber')? wrongInput : ''}`}
                    label='PAN Number'
                    type='text' 
                    name='PANNumber'
                    id='PANNumber'
                    value={props.filledValues.PANNumber}
                    required
                />

                <InputBox 
                    key={paymentCopyKey}
                    className={`${errorModalCtx.errorFromComp.includes('paymentCopyLocation')? wrongInput : ''}`}
                    label='Payment Copy'
                    type='file' 
                    name='paymentCopyLocation'
                    id='paymentCopyLocation'
                    accept={IMG_DOC_ACCEPTED_FILE_FORMAT.toString()}
                    onChange={handelFileSelection}
                    value={props.filledValues.paymentCopyLocation}
                    fileUnselectHandler={props.userFileUnselectHandler}
                    required
                />

                <div className={formActionsCN}>
                    <Button
                        type='button'
                        className='negative'
                        onClick={handelViewChange}
                        dataAttributes={{'data-change-state-to': 'PREVIOUS'}}
                    >
                        <i className="lni lni-angle-double-left" data-change-state-to='PREVIOUS'></i>
                        Previous
                    </Button>
                    <Button
                        type='submit'
                        className='default'
                    >
                        Next
                        <i className="lni lni-angle-double-right" ></i>
                    </Button>
                </div>
            </form>
        </>
    );
}

export default BFPage9;

















// // Functional Imports
// import { useEffect, useReducer, useState } from 'react';

// // Components Import
// import InputBox, { getValueFromInputBoxByName } from '../../../UI/InputBox/InputBox';
// import PopupListBox, { fetchSelectedOption } from '../../../UI/PopupListBox/PopupListBox';
// import Button from '../../../UI/Button/Button';
// import Modal from '../../../UI/ShowModal/ShowModal';
// import AddressInput from '../../../UI/AddressInput/AddressInput';

// // Styled Imports
// import formUtilityStyles from '../../BookingForm.module.css';

// const { 'form-actions' : formActionsCN, 'wrong-input': wrongInput } = formUtilityStyles;


// function errorModalReducer( state, action ){
//     if( action.type === 'NEW_ERROR' ){
//         return { display: true, title: action.title, message: action.message, className: [action.name] };
//     }

//     if( action.type === 'CLOSE_MODAL' ){
//         return { modalDisplay: false, title: '', message: '', className: [...state.className]}
//     }
// }

// function BFPage6(props) {

//     const [ errorState, dispatchErrorState ] = useReducer(
//         errorModalReducer,  { display: false, title: '', message: '', className: [] }
//     );

//     function handelViewChange(event){
//         props.changeView( event.target.getAttribute('data-change-state-to') );
//     }

//     function formSubmitHandler(event){
//         event.preventDefault();
        
//     }

//     function closeModal(){
//         dispatchErrorState({ type: 'CLOSE_MODAL'} );
//     }

//     return (
//         <>
//             <form onSubmit={formSubmitHandler}>
//                 <InputBox 
//                     className={errorState.className.includes('occupation') && wrongInput}
//                     label='Occupation'
//                     type='text' 
//                     name='occupation'
//                     id='occupation'
//                     value={props.filledValues.occupation}
//                     required
//                 />

//                 <InputBox 
//                     className={errorState.className.includes('designation') && wrongInput}
//                     label='Designation'
//                     type='text' 
//                     name='designation'
//                     id='designation'
//                     value={props.filledValues.designation}
//                     required
//                 />

//                 <InputBox 
//                     className={errorState.className.includes('companyName') && wrongInput}
//                     label='Company Name'
//                     type='text' 
//                     name='companyName'
//                     id='company Name'
//                     value={props.filledValues.companyName}
//                     required
//                 />

//                 <InputBox 
//                     className={errorState.className.includes('industry') && wrongInput}
//                     label='Industry'
//                     type='text' 
//                     name='industry'
//                     id='company Name'
//                     value={props.filledValues.industry}
//                     required
//                 />

//                 <div className={formActionsCN}>
//                     <Button
//                         type='button'
//                         className='negative'
//                         onClick={handelViewChange}
//                         dataAttributes={{'data-change-state-to': 'PREVIOUS'}}
//                     >
//                         <i className="lni lni-angle-double-left" data-change-state-to='PREVIOUS'></i>
//                         Previous
//                     </Button>
//                     <Button
//                         type='submit'
//                         className='default'
//                     >
//                         Next
//                         <i className="lni lni-angle-double-right" ></i>
//                     </Button>
//                 </div>
//             </form>
//             {errorState.display && <Modal title={errorState.title} message={errorState.message} closeModal={closeModal}/>}
//         </>
//     );
// }

// export default BFPage6;