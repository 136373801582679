// Functional Imports
import React from 'react';
import { ErrorModalContextProvider } from './context/error-modal-context/error-modal-context';

// Components Import
import Container from './components/UI/Container/Container';
import BookingForm from './components/BookingForm/BookingForm';

// Styles Import & Assets
import styles from './App.module.css';

function App() {
    return (
        <>
            <div className={styles['booking-process-container']}>
                <ErrorModalContextProvider>
                    <Container>
                        <BookingForm />
                    </Container>
                </ErrorModalContextProvider>
                <div className={styles['background-container']} />
                <ul className={styles['circles']}>
                    <li /> <li /> <li /> <li /> <li /> <li /> <li /> <li /> <li /> <li />
                </ul>
            </div>
        </>
    );
}

export default App;
// https://codepen.io/alvarotrigo/pen/GRvYNax