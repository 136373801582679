// Functional Imports

// Components Import
import InputBox from '../../../UI/InputBox/InputBox';
import PopupListBox from '../../../UI/PopupListBox/PopupListBox';
import Button from '../../../UI/Button/Button';

// Styled Imports
import formUtilityStyles from '../../BookingForm.module.css';

const { 'form-actions' : formActionsCN } = formUtilityStyles;

function BFPage1(props) {

    function handelViewChange(eventOrState){ 
        const viewState = eventOrState?.target?.getAttribute('data-change-state-to') || eventOrState;
        props.changeView(viewState);
    }

    async function formSubmitHandler(event){
        event.preventDefault();
        props.formSubmitHandler();
        handelViewChange('NEXT');
    }

    return (
        <>
            <form onSubmit={formSubmitHandler}>
                <PopupListBox
                    label='Project Name'
                    popupListData={[props.filledValues.Project.name]}
                    defaultOption={props.filledValues.Project.name}
                    required
                    disabled
                />
                <PopupListBox
                    label='Unit Type'
                    popupListData={[props.filledValues.ProjectUnit.type]}
                    defaultOption={props.filledValues.ProjectUnit.type}
                    required
                    disabled
                />
                <InputBox 
                    label='Unit No' 
                    type='text' 
                    value={props.filledValues.ProjectUnit.name}
                    required 
                    disabled
                />
                <div className={formActionsCN}>
                    <Button
                        type='button'
                        className='negative'
                        onClick={handelViewChange}
                        dataAttributes={{'data-change-state-to': 'PREVIOUS'}}
                    >
                        <i className="lni lni-angle-double-left" data-change-state-to='PREVIOUS'></i>
                        Previous
                    </Button>
                    <Button
                        type='submit'
                        className='default'
                    >
                        Next
                        <i className="lni lni-angle-double-right" ></i>
                    </Button>
                </div>
            </form>
        </>
    );
}

export default BFPage1;