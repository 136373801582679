// Components Import
import { useEffect, useState } from 'react';
import Button from '../../UI/Button/Button';
import Separtor from '../../UI/Separator/Separator'

// Styles import
import styles from './BFInstructions.module.css';
import formUtilityStyles from '../BookingForm.module.css';

const { 'form-actions' : formActionsCN } = formUtilityStyles;

function BFInstructions( props ){

    // TODO: Fix push of data to server on every change of tac chekcbox

    const [tacChecked, setTacChecked] = useState(false);

    useEffect(() => {
        setTacChecked(props.bookingFormData.agreedToTermsAndCondition)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function handelViewChange(event){
        props.changeView( event.target.getAttribute('data-change-state-to') );
    }

    function handelTermsAndConditionCheckbox(event){
        setTacChecked(event.target.checked);
        props.tacAgreeHandler({ agreedToTermsAndCondition: event.target.checked })
    }


    return (
        <>
            <div className={styles["instructions-container"]}>
                <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Accusamus dicta sit, qui magni beatae voluptatum?, qui magni beatae voluptatum?</p>
                <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Accusamus dicta sit, qui magni beatae voluptatum?, qui magni beatae voluptatum?</p>
                <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Accusamus dicta sit, qui magni beatae voluptatum?, qui magni beatae voluptatum?</p>
                <Separtor type='no-circle' />
                <div className={styles["tac-container"]}>
                    <input type='checkbox' id='tac' onChange={handelTermsAndConditionCheckbox} checked={tacChecked}/>
                    <label htmlFor='tac'><strong>I Agree to the</strong> <a href='hhtps://#'>Terms & Conditions</a></label>
                </div>
            </div>
                <div className={formActionsCN}>
                    <Button 
                        type="button" 
                        className="negative" 
                        onChange={handelViewChange} 
                        onClick={handelViewChange} 
                        dataAttributes={{'data-change-state-to': 'PREVIOUS'}}
                    >
                        <i className="lni lni-angle-double-left" data-change-state-to='PREVIOUS'></i>
                        Previous
                    </Button>
                    <Button
                        type="button"
                        className='default'
                        onClick={handelViewChange} 
                        dataAttributes={{'data-change-state-to': 'NEXT'}}
                        disabled={!tacChecked}
                        checked={tacChecked}
                    >
                        Continue
                        <i className="lni lni-angle-double-right" data-change-state-to='NEXT'></i>
                    </Button>
                </div>
        </>
    )
}

export default BFInstructions;