// Functional Imports
import { useReducer, useEffect } from 'react';
import { isEmpty } from '../../../../Utility/Utility';
import { errorModalReducer, addressReducer } from './BFPage14_Helper';

// Components Import
import AddressInput, { getAddressDataFromFormByName } from '../../../UI/AddressInput/AddressInput';
import PopupListBox, { fetchSelectedOption } from '../../../UI/PopupListBox/PopupListBox';
import Button from '../../../UI/Button/Button';
import Modal from '../../../UI/ShowModal/ShowModal';

// Styled Imports
import formUtilityStyles from '../../BookingForm.module.css';

const PERMANENT_ADDRESS = ['Select One', 'Same As Present Address', 'Different'];
const { 'form-actions' : formActionsCN, 'wrong-input': wrongInput } = formUtilityStyles;

function BFPage5(props){

    const [ errorState, dispatchErrorState ] = useReducer( errorModalReducer,  { display: false, title: '', message: '', className: [] } );

    const [ addressState, dispatchAddress ] = useReducer(
                addressReducer, { 
                coApp_presentAddress: (props?.filledValues?.coApp_presentAddress)? props.filledValues.coApp_presentAddress : {}, 
                coApp_permanentAddressType: (props?.filledValues?.coApp_permanentAddressType)? props.filledValues.coApp_permanentAddressType : '', 
                coApp_permanentAddress: (props?.filledValues?.coApp_permanentAddress)? props.filledValues.coApp_permanentAddress : {}, 
                coApplicantPermanentAddressDisabled: true 
        }
    );

    useEffect(() => { dispatchAddress({ type: 'RE-EVALUATE' }) }, []); 

    function handelViewChange(event){
        props.changeView( event.target.getAttribute('data-change-state-to') );
    }

    function handelAddressChange(event){
        if( event.target.name === 'coApp_permanentAddressType' )
            dispatchAddress({ type: 'PERMANENT_ADDRESS_TYPE', value: event.target.value })
    }

    async function formSubmitHandler(event){
        event.preventDefault();

        const coApp_permanentAddressType = fetchSelectedOption(event, 'coApp_permanentAddressType').selectedValue;
        const coApp_permanentAddress = getAddressDataFromFormByName(event, 'coApp_permanentAddress');

        if( coApp_permanentAddressType === 'Select One' ){
            dispatchErrorState({ type: 'NEW_ERROR', name: 'coApp_permanentAddressType', title: 'Invalid Input!', message: 'Please select a valid permanent address type' });  
            return;  
        }

        if( isEmpty( coApp_permanentAddress.addressLine1 ) ){
            dispatchErrorState({ type: 'NEW_ERROR', title: 'Invalid Input!', message: 'Please enter a valid line1 address' });
            return; 
        }

        if( isEmpty( coApp_permanentAddress.country ) ){
            dispatchErrorState({ type: 'NEW_ERROR', title: 'Invalid Input!', message: 'Please enter a valid country' });
            return; 
        }

        if( isEmpty( coApp_permanentAddress.state ) ){
            dispatchErrorState({ type: 'NEW_ERROR', title: 'Invalid Input!', message: 'Please enter a valid state' });
            return; 
        }

        if( isEmpty( coApp_permanentAddress.city ) ){
            dispatchErrorState({ type: 'NEW_ERROR', title: 'Invalid Input!', message: 'Please enter a valid city' });
            return; 
        }

        if( isEmpty( coApp_permanentAddress.pincode ) ){
            dispatchErrorState({ type: 'NEW_ERROR', title: 'Invalid Input!', message: 'Please enter a valid pincode' });
            return; 
        }

        const payLoad = { coApp_permanentAddressType, coApp_permanentAddress, submittedByUser: true };

        if(await props.formSubmitHandler(payLoad)) props.refreshData();
    }

    function closeModal(){
        dispatchErrorState({ type: 'CLOSE_MODAL'});
    }

    return (
        <>
            <form onSubmit={formSubmitHandler}>

                <PopupListBox 
                    className={`${errorState.className.includes('coApp_permanentAddressType')? wrongInput : ''}`}
                    label='Co-App. Permanent Address Type'
                    name='coApp_permanentAddressType'
                    popupListData={PERMANENT_ADDRESS}
                    defaultOption={addressState.coApp_permanentAddressType}
                    onChange={handelAddressChange}
                    required
                /> 

                <AddressInput
                    label='Permanent Address'
                    labelSpan='(For Agreement)'
                    name='coApp_permanentAddress'
                    id='coApp_permanentAddress'
                    value={addressState.coApp_permanentAddress}
                    disabled={addressState.coApplicantPermanentAddressDisabled}
                    required
                />

                <div className={formActionsCN}>
                    <Button
                        type='button'
                        className='negative'
                        onClick={handelViewChange}
                        dataAttributes={{'data-change-state-to': 'PREVIOUS'}}
                    >
                        <i className="lni lni-angle-double-left" data-change-state-to='PREVIOUS'></i>
                        Previous
                    </Button>
                    <Button
                        type='submit'
                        className='default'
                    >
                        Save
                        <i className="lni lni-save"></i>
                    </Button>
                </div>
            </form>
            {errorState.display && <Modal title={errorState.title} message={errorState.message} closeModal={closeModal}/>}
        </>
    );
}

export default BFPage5;