// Functional imports
import { openBase64NewTab, downloadAsPDF } from './BFPriceSheetHelper';

// Components Import
import Button from '../../UI/Button/Button';

// Styles import
import styles from './BFPriceSheet.module.css';

const DOC = { pdf: null };

function BFPriceSheet( props ){

    async function fetchPriceSheet(){
        try{    
            const url = props.urlEndpointWithAccessToken.url + '/retrieve-attachments?retrieve=PRICE_SHEET&accessToken=' + props.urlEndpointWithAccessToken.accessToken;
            const method = 'GET';

            props.setDisplayLoader(true);

            const response = await fetch(url, { method, credentials: 'include' });

            if(!response.ok){
                if(response.headers.get('Content-Type').includes('application/json')){
                    const errResponse = await response.json();
                    throw errResponse;
                }
            }

            const responseJSON = await response.json();

            DOC.pdf = responseJSON.PRICE_SHEET;
        } catch(err) {
            props.setAlternateHeaderInfo({ header: 'Request Failed!', body: err.msg || 'Failed to retrive Price Sheet data' });
        }
        props.setDisplayLoader(false);
    }

    async function openPDFInNewTab(){
        if(!DOC.pdf) await fetchPriceSheet();
        if(DOC.pdf) openBase64NewTab(DOC.pdf)
    }

    async function downloadPDF(){
        if(!DOC.pdf) await fetchPriceSheet();
        downloadAsPDF(DOC.pdf, props.bookingFormData.priceSheetLocation);
    }

    function handleAgreeAndContinue(){
        props.changeView('NEXT');
    }

    return (
        props.bookingFormData.priceSheetLocation && (
            <div className={styles['price-sheet-container']}>
                <div className={styles['price-sheet-name']}>
                    {props.bookingFormData.priceSheetLocation}
                </div>
                <div className={styles['price-sheet-actions']}>
                    <Button className={`negative ${styles['btn-rm-border']}`} type='button' onClick={openPDFInNewTab}>
                        Open
                        <i className="lni lni-arrow-top-right"></i>
                    </Button>
                    <Button className='default' type='button' onClick={downloadPDF}>
                        Download
                        <i className="lni lni-download"></i>
                    </Button>
                </div>
                <div className={styles['price-sheet-actions']}>
                    <Button className='default' type='button' onClick={handleAgreeAndContinue}>
                        Agree & Continue
                        <i className="lni lni-angle-double-right"></i>
                    </Button>
                </div>
            </div>
        )
    )
}



export default BFPriceSheet;