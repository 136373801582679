// Functional Imports

// Components Import
import InputBox, { getValueFromInputBoxByName } from '../../../UI/InputBox/InputBox';
import Button from '../../../UI/Button/Button';
import AddressInput, { getAddressDataFromFormByName } from '../../../UI/AddressInput/AddressInput';

// Styled Imports
import formUtilityStyles from '../../BookingForm.module.css';

const { 'form-actions' : formActionsCN } = formUtilityStyles;



function BFPage7(props) {
    function handelViewChange(eventOrState){ 
        const viewState = eventOrState?.target?.getAttribute('data-change-state-to') || eventOrState;
        props.changeView(viewState);
    }

    async function formSubmitHandler(event){
        event.preventDefault();

        const annualIncome  = getValueFromInputBoxByName(event, 'annualIncome');
        const officeAddress = getAddressDataFromFormByName(event, 'officeAddress');

        const payLoad = { annualIncome, officeAddress };

        if(await props.formSubmitHandler(payLoad)) handelViewChange('NEXT')
    }

    return (
        <>
            <form onSubmit={formSubmitHandler}>
                <InputBox 
                    label='Annual Income'
                    type='text' 
                    name='annualIncome'
                    id='annualIncome'
                    value={props.filledValues.annualIncome}
                /> 


                <AddressInput
                    label='Office Address'
                    name='officeAddress'
                    id='officeAddress'
                    value={props.filledValues.officeAddress}
                />

                <div className={formActionsCN}>
                    <Button
                        type='button'
                        className='negative'
                        onClick={handelViewChange}
                        dataAttributes={{'data-change-state-to': 'PREVIOUS'}}
                    >
                        <i className="lni lni-angle-double-left" data-change-state-to='PREVIOUS'></i>
                        Previous
                    </Button>
                    <Button
                        type='submit'
                        className='default'
                    >
                        Next
                        <i className="lni lni-angle-double-right" ></i>
                    </Button>
                </div>
            </form>
        </>
    );
}

export default BFPage7;

















// // Functional Imports
// import { useEffect, useReducer, useState } from 'react';

// // Components Import
// import InputBox, { getValueFromInputBoxByName } from '../../../UI/InputBox/InputBox';
// import PopupListBox, { fetchSelectedOption } from '../../../UI/PopupListBox/PopupListBox';
// import Button from '../../../UI/Button/Button';
// import Modal from '../../../UI/ShowModal/ShowModal';
// import AddressInput from '../../../UI/AddressInput/AddressInput';

// // Styled Imports
// import formUtilityStyles from '../../BookingForm.module.css';

// const { 'form-actions' : formActionsCN, 'wrong-input': wrongInput } = formUtilityStyles;


// function errorModalReducer( state, action ){
//     if( action.type === 'NEW_ERROR' ){
//         return { display: true, title: action.title, message: action.message, className: [action.name] };
//     }

//     if( action.type === 'CLOSE_MODAL' ){
//         return { modalDisplay: false, title: '', message: '', className: [...state.className]}
//     }
// }

// function BFPage6(props) {

//     const [ errorState, dispatchErrorState ] = useReducer(
//         errorModalReducer,  { display: false, title: '', message: '', className: [] }
//     );

//     function handelViewChange(event){
//         props.changeView( event.target.getAttribute('data-change-state-to') );
//     }

//     function formSubmitHandler(event){
//         event.preventDefault();
        
//     }

//     function closeModal(){
//         dispatchErrorState({ type: 'CLOSE_MODAL'} );
//     }

//     return (
//         <>
//             <form onSubmit={formSubmitHandler}>
//                 <InputBox 
//                     className={errorState.className.includes('occupation') && wrongInput}
//                     label='Occupation'
//                     type='text' 
//                     name='occupation'
//                     id='occupation'
//                     value={props.filledValues.occupation}
//                     required
//                 />

//                 <InputBox 
//                     className={errorState.className.includes('designation') && wrongInput}
//                     label='Designation'
//                     type='text' 
//                     name='designation'
//                     id='designation'
//                     value={props.filledValues.designation}
//                     required
//                 />

//                 <InputBox 
//                     className={errorState.className.includes('companyName') && wrongInput}
//                     label='Company Name'
//                     type='text' 
//                     name='companyName'
//                     id='company Name'
//                     value={props.filledValues.companyName}
//                     required
//                 />

//                 <InputBox 
//                     className={errorState.className.includes('industry') && wrongInput}
//                     label='Industry'
//                     type='text' 
//                     name='industry'
//                     id='company Name'
//                     value={props.filledValues.industry}
//                     required
//                 />

//                 <div className={formActionsCN}>
//                     <Button
//                         type='button'
//                         className='negative'
//                         onClick={handelViewChange}
//                         dataAttributes={{'data-change-state-to': 'PREVIOUS'}}
//                     >
//                         <i className="lni lni-angle-double-left" data-change-state-to='PREVIOUS'></i>
//                         Previous
//                     </Button>
//                     <Button
//                         type='submit'
//                         className='default'
//                     >
//                         Next
//                         <i className="lni lni-angle-double-right" ></i>
//                     </Button>
//                 </div>
//             </form>
//             {errorState.display && <Modal title={errorState.title} message={errorState.message} closeModal={closeModal}/>}
//         </>
//     );
// }

// export default BFPage6;