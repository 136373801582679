// Functional Imports
import { useEffect, useState } from "react";
import { isEmpty } from "../../../../Utility/Utility";

// Component Imports
import Button from "../../../UI/Button/Button";

// Style Imports

function ResendOTPButton(props){
    const defaultButtonState = { name: 'Resend OTP', disabled: true, timer: isEmpty(props.timer)? 35 : props.timer };

    const [buttonStatus, setButtonStatus] = useState(defaultButtonState);

    function handelButtonClick(event){
        setButtonStatus(defaultButtonState);
        props.onClick && props.onClick(event);
    }

    useEffect(() => {
        if(!buttonStatus.disabled) return;

        const interval = setInterval(() => {
            const newTimmer = buttonStatus.timer--;
            setButtonStatus(prev => ({...prev, timer: newTimmer}))
            if(buttonStatus.timer < 0) return clearInterval(interval);
        }, 1000);
        
        
        return () => clearInterval(interval);
    }, [buttonStatus.disabled])

    if(buttonStatus.timer <= 0 & buttonStatus.disabled === true)
        setButtonStatus(prev => ({...prev, disabled: false}))
    

    return (
        <Button
            type='submit'
            className='negative' 
            style={{ border: 'none' }}
            disabled={buttonStatus.disabled}
            onClick={handelButtonClick}
        >
            {buttonStatus.timer === 0? buttonStatus.name : `${buttonStatus.name} in ${buttonStatus.timer}`}
        </Button>
    );
}

export default ResendOTPButton;