// Functional Import
import React, { useState } from 'react';

// Components Import
import BFPage1 from './BFPage1/BFPage1';
import BFPage2 from './BFPage2/BFPage2';
import BFPage3 from './BFPage3/BFPage3';
import BFPage4 from './BFPage4/BFPage4';
import BFPage5 from './BFPage5/BFPage5';
import BFPage6 from './BFPage6/BFPage6';
import BFPage7 from './BFPage7/BFPage7';
import BFPage8 from './BFPage8/BFPage8';
import BFPage9 from './BFPage9/BFPage9';
import BFPage10 from './BFPage10/BFPage10';
// Co - Applicant
import BFPage11 from './BFPage11/BFPage11';
import BFPage12 from './BFPage12/BFPage12';
import BFPage13 from './BFPage13/BFPage13';
import BFPage14 from './BFPage14/BFPage14';

// Styled Imports
import styles from './BFUserInputForm.module.css';
import { isEmpty } from '../../../Utility/Utility';


function BFUserInputForm(props){    

    const [ currentUBFState, setUBFState ] = useState(0);

    function changeUBFState( state ){
        if( currentUBFState === 0 && state === 'PREVIOUS' ) props.changeParentView('PREVIOUS');
        if( state === 'PREVIOUS' ) setUBFState( currentUBFState - 1 );
        if( state === 'NEXT' && currentUBFState < 13 ) setUBFState( currentUBFState + 1 );
    }

    function formSubmitHandler(payload){
        return !isEmpty(payload) && props.formSubmitHandler(payload)
    }
    
    const USER_BOOKING_FORM_VIEWS = [
        <BFPage1 changeView={changeUBFState} filledValues={props.filledValues} formSubmitHandler={formSubmitHandler}/>,
        <BFPage2 changeView={changeUBFState} filledValues={props.filledValues} formSubmitHandler={formSubmitHandler}/>,
        <BFPage3 changeView={changeUBFState} filledValues={props.filledValues} formSubmitHandler={formSubmitHandler}/>,
        <BFPage4 changeView={changeUBFState} filledValues={props.filledValues} formSubmitHandler={formSubmitHandler}/>,
        <BFPage5 changeView={changeUBFState} filledValues={props.filledValues} formSubmitHandler={formSubmitHandler}/>,
        <BFPage6 changeView={changeUBFState} filledValues={props.filledValues} formSubmitHandler={formSubmitHandler}/>,
        <BFPage7 changeView={changeUBFState} filledValues={props.filledValues} formSubmitHandler={formSubmitHandler}/>,
        <BFPage8 changeView={changeUBFState} filledValues={props.filledValues} formSubmitHandler={formSubmitHandler}/>,

        <BFPage9 
            changeView={changeUBFState} 
            filledValues={props.filledValues} 
            formSubmitHandler={formSubmitHandler} 
            userFileUnselectHandler={props.userFileUnselectHandler} 
            setDisplayLoader={props.setDisplayLoader}
        />,

        <BFPage10
            changeView={changeUBFState}
            filledValues={props.filledValues}
            formSubmitHandler={formSubmitHandler}
            setRefreshState={props.setRefreshState} 
            userFileUnselectHandler={props.userFileUnselectHandler}     
            setDisplayLoader={props.setDisplayLoader}
            refreshData={props.refreshData}
        />,

        <BFPage11 changeView={changeUBFState} filledValues={props.filledValues} formSubmitHandler={formSubmitHandler}/>,
        <BFPage12 changeView={changeUBFState} filledValues={props.filledValues} formSubmitHandler={formSubmitHandler}/>,

        <BFPage13
            changeView={changeUBFState}
            filledValues={props.filledValues}
            formSubmitHandler={formSubmitHandler}
            userFileUnselectHandler={props.userFileUnselectHandler}
            setDisplayLoader={props.setDisplayLoader
        }/>,


        <BFPage14 
            changeView={changeUBFState} 
            filledValues={props.filledValues} 
            formSubmitHandler={formSubmitHandler}
            refreshData={props.refreshData}
        />,
    ];

    return (
        <div className={styles['form-main-container']}>
            {USER_BOOKING_FORM_VIEWS[currentUBFState]}
        </div>
    )
}

export default BFUserInputForm;