// Functional Imports
import { useContext } from 'react';
import ErrorModalContext from '../../../../context/error-modal-context/error-modal-context';

// Components Import
import InputBox, { getValueFromInputBoxByName } from '../../../UI/InputBox/InputBox';
import PopupListBox, { fetchSelectedOption } from '../../../UI/PopupListBox/PopupListBox';
import Button from '../../../UI/Button/Button';

// Styled Imports
import formUtilityStyles from '../../BookingForm.module.css';


const { 'form-actions' : formActionsCN, 'wrong-input': wrongInput } = formUtilityStyles;

const MARITAL_STATUS = ['Select One', 'Single', 'Married'];

function BFPage3(props) {

    const errorModalCtx = useContext( ErrorModalContext );

    function handelViewChange(eventOrState){ 
        const viewState = eventOrState?.target?.getAttribute('data-change-state-to') || eventOrState;
        props.changeView(viewState);
    }

    async function formSubmitHandler(event){
        event.preventDefault();

        const mobileNumber = getValueFromInputBoxByName(event, 'mobileNumber').trim();
        const alternateMobileNumber = getValueFromInputBoxByName(event, 'alternateMobileNumber').trim();
        let maritalStatus = fetchSelectedOption(event, 'maritalStatus').selectedValue;

        if( mobileNumber.length < 10 ){
            errorModalCtx.showError('Invalid Input!', 'Please enter a valid mobile number.', ['mobileNumber'] );
            return;
        }

        if( alternateMobileNumber.length > 0 && alternateMobileNumber.length < 10  ){
            errorModalCtx.showError('Invalid Input!', 'Please enter a valid mobile number.', ['alternateMobileNumber']);
            return;
        }

        errorModalCtx.clearCompErrorList();

        const payLoad = { mobileNumber, alternateMobileNumber, maritalStatus };

        if(await props.formSubmitHandler(payLoad)) handelViewChange('NEXT')
    }

    return (
        <>
            <form onSubmit={formSubmitHandler}>
                <InputBox 
                    className={`${(errorModalCtx.errorFromComp.includes('emailId'))? wrongInput : ''}`}
                    label='Email Id'
                    type='email' 
                    name='emailId'
                    id='emailId'
                    value={props.filledValues.emailId}
                    required
                    disabled
                />

                <InputBox 
                    className={`${(errorModalCtx.errorFromComp.includes('mobileNumber'))? wrongInput : ''}`}
                    label='Mobile Number'
                    labelSpan='(Linked To Aadhaar)' 
                    name='mobileNumber'
                    id='mobileNumber'
                    type='text'
                    value={props.filledValues.mobileNumber}
                    required 
                />

                <InputBox 
                    className={`${(errorModalCtx.errorFromComp.includes('alternateMobileNumber'))? wrongInput : ''}`}
                    label='Alternate Mobile Number'
                    name='alternateMobileNumber'
                    id='alternateMobileNumber'
                    type='text'
                    value={props.filledValues.alternateMobileNumber} 
                />

                <PopupListBox 
                    className={`${(errorModalCtx.errorFromComp.includes('maritalStatus'))? wrongInput : ''}`}
                    label='Marital Status'
                    name='maritalStatus'
                    popupListData={MARITAL_STATUS}
                    defaultOption={props.filledValues.maritalStatus}
                />


                <div className={formActionsCN}>
                    <Button
                        type='button'
                        className='negative'
                        onClick={handelViewChange}
                        dataAttributes={{'data-change-state-to': 'PREVIOUS'}}
                    >
                        <i className="lni lni-angle-double-left" data-change-state-to='PREVIOUS'></i>
                        Previous
                    </Button>
                    <Button
                        type='submit'
                        className='default'
                    >
                        Next
                        <i className="lni lni-angle-double-right" ></i>
                    </Button>
                </div>
            </form>
        </>
    );
}

export default BFPage3;