// Functional Imports
import { useReducer } from 'react';

// Components Import
import InputBox, { getValueFromInputBoxByName } from '../../../UI/InputBox/InputBox';
import PopupListBox, { fetchSelectedOption } from '../../../UI/PopupListBox/PopupListBox';
import Button from '../../../UI/Button/Button';
import Modal from '../../../UI/ShowModal/ShowModal';

// Styled Imports
import formUtilityStyles from '../../BookingForm.module.css';


const { 'form-actions' : formActionsCN, 'wrong-input': wrongInput } = formUtilityStyles;

const MARITAL_STATUS = ['Select One', 'Single', 'Married'];

function errorModalReducer( state, action ){
    if( action.type === 'NEW_ERROR' )
        return { display: true, title: action.title, message: action.message, className: [ action.name ] }

    if( action.type === 'CLOSE_MODAL' )
        return { modalDisplay: false, title: '', message: '', className: [...state.className]}
}

function BFPage12(props) {

    const [ errorState, dispatchErrorState ] = useReducer(
        errorModalReducer,  { display: false, title: '', message: '', className: [] }
    );

    function handelViewChange(eventOrState){ 
        const viewState = eventOrState?.target?.getAttribute('data-change-state-to') || eventOrState;
        props.changeView(viewState);
    }


    async function formSubmitHandler(event){
        event.preventDefault();

        const coApp_mobileNumber = getValueFromInputBoxByName(event, 'coApp_mobileNumber');
        const coApp_alternateMobileNumber = getValueFromInputBoxByName(event, 'coApp_alternateMobileNumber');
        const coApp_emailId = getValueFromInputBoxByName(event, 'coApp_emailId');
        let coApp_maritalStatus = fetchSelectedOption(event, 'coApp_maritalStatus').selectedValue;

        if( coApp_mobileNumber.length < 10 ){
            dispatchErrorState({ type: 'NEW_ERROR', name: 'coApp_mobileNumber', title: 'Invalid Input!', message: 'Please enter a valid mobile number.' });
            return;
        }

        if( coApp_alternateMobileNumber.length > 0 && coApp_alternateMobileNumber.length < 10  ){
            dispatchErrorState({ type: 'NEW_ERROR', name: 'coApp_alternateMobileNumber', title: 'Invalid Input!', message: 'Please enter a valid mobile number.' });
            return;
        }

        if( !coApp_emailId.includes('@') || !coApp_emailId.includes('.') ){
            dispatchErrorState({ type: 'NEW_ERROR', name: 'coApp_emailId', title: 'Invalid Input!', message: 'Please enter a valid email id.' });
            return;
        }

        const payLoad = { coApp_mobileNumber, coApp_alternateMobileNumber, coApp_emailId, coApp_maritalStatus };

        if(await props.formSubmitHandler(payLoad)) handelViewChange('NEXT')
    }

    function closeModal(){
        dispatchErrorState({ type: 'CLOSE_MODAL'} );
    }

    return (
        <>
            <form onSubmit={formSubmitHandler}>
                <InputBox 
                    className={errorState.className.includes('coApp_emailId') && wrongInput}
                    label='Email Id'
                    labelSpan='(Co-Applicant)'
                    type='email' 
                    name='coApp_emailId'
                    id='coApp_emailId'
                    value={props.filledValues.coApp_emailId}
                    required
                />

                <InputBox 
                    className={errorState.className.includes('coApp_mobileNumber') && wrongInput}
                    label='Mobile Number'
                    labelSpan='(Co-Applicant)' 
                    name='coApp_mobileNumber'
                    id='coApp_mobileNumber'
                    type='text'
                    value={props.filledValues.coApp_mobileNumber}
                    required 
                />

                <InputBox 
                    className={errorState.className.includes('coApp_alternateMobileNumber') && wrongInput}
                    label='Alternate Mobile Number'
                    name='coApp_alternateMobileNumber'
                    id='coApp_alternateMobileNumber'
                    type='text'
                    value={props.filledValues.coApp_alternateMobileNumber} 
                />

                <PopupListBox 
                    className={errorState.className.includes('coApp_maritalStatus') && wrongInput}
                    label='Marital Status'
                    name='coApp_maritalStatus'
                    popupListData={MARITAL_STATUS}
                    defaultOption={props.filledValues.coApp_maritalStatus}
                />


                <div className={formActionsCN}>
                    <Button
                        type='button'
                        className='negative'
                        onClick={handelViewChange}
                        dataAttributes={{'data-change-state-to': 'PREVIOUS'}}
                    >
                        <i className="lni lni-angle-double-left" data-change-state-to='PREVIOUS'></i>
                        Previous
                    </Button>
                    <Button
                        type='submit'
                        className='default'
                    >
                        Next
                        <i className="lni lni-angle-double-right" ></i>
                    </Button>
                </div>
            </form>
            {errorState.display && <Modal title={errorState.title} message={errorState.message} closeModal={closeModal}/>}
        </>
    );
}

export default BFPage12;