// Styled Imports
import styles from './Separator.module.css';

function Separator( props ){

    if( props.type === 'no-circle' ){
        return (
            <div className={styles.separator}></div>
        );
    }

    return (
        <div className={styles.separator}>
            <span></span>
        </div>
    );
}

export default Separator;