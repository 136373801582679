// Functional Imports
import { useContext, useEffect, useReducer } from 'react';
import { isEmpty } from '../../../../Utility/Utility';
import ErrorModalContext from '../../../../context/error-modal-context/error-modal-context';

// Components Import
import AddressInput, { getAddressDataFromFormByName } from '../../../UI/AddressInput/AddressInput';
import PopupListBox, { fetchSelectedOption } from '../../../UI/PopupListBox/PopupListBox';
import Button from '../../../UI/Button/Button';

// Styled Imports
import formUtilityStyles from '../../BookingForm.module.css';

const PERMANENT_ADDRESS = ['Select One', 'Same As Present Address', 'Different'];
const { 'form-actions' : formActionsCN, 'wrong-input': wrongInput } = formUtilityStyles;

function BFPage5(props){
    const [ addressState, dispatchAddress ] = useReducer(
                addressReducer, { 
                presentAddress: (props?.filledValues?.presentAddress)? props.filledValues.presentAddress : {}, 
                permanentAddressType: (props?.filledValues?.permanentAddressType)? props.filledValues.permanentAddressType : '', 
                permanentAddress: (props?.filledValues?.permanentAddress)? props.filledValues.permanentAddress : {}, 
                permanentAddressDisabled: true 
        }
    );

    const errorModalCtx = useContext(ErrorModalContext);

    useEffect(() => { dispatchAddress({ type: 'RE-EVALUATE' }) }, []); 

    function handelViewChange(eventOrState){ 
        const viewState = eventOrState?.target?.getAttribute('data-change-state-to') || eventOrState;
        props.changeView(viewState);
    }

    function handelAddressChange(event){
        if( event.target.name === 'permanentAddressType' )
            dispatchAddress({ type: 'PERMANENT_ADDRESS_TYPE', value: event.target.value })
    }

    async function formSubmitHandler(event){
        event.preventDefault();

        const permanentAddressType = fetchSelectedOption(event, 'permanentAddressType').selectedValue;
        const permanentAddress = getAddressDataFromFormByName(event, 'permanentAddress');

        if( permanentAddressType === 'Select One' ){
            errorModalCtx.showError('Invalid Input!', 'Please select a valid permanent address type' );
            return;  
        }

        if( isEmpty( permanentAddress.addressLine1 ) ){
            errorModalCtx.showError('Invalid Input!', 'Please enter a valid line1 address' );
            return; 
        }

        if( isEmpty( permanentAddress.country ) ){
            errorModalCtx.showError('Invalid Input!', 'Please enter a valid country' );
            return; 
        }

        if( isEmpty( permanentAddress.state ) ){
            errorModalCtx.showError('Invalid Input!', 'Please enter a valid state' );
            return; 
        }

        if( isEmpty( permanentAddress.city ) ){
            errorModalCtx.showError('Invalid Input!', 'Please enter a valid city' );
            return; 
        }

        if( isEmpty( permanentAddress.pincode ) ){
            errorModalCtx.showError('Invalid Input!', 'Please enter a valid pincode' );
            return; 
        }

        errorModalCtx.clearCompErrorList();

        const payLoad = { permanentAddressType, permanentAddress };

        if(await props.formSubmitHandler(payLoad)) handelViewChange('NEXT')
    }

    return (
        <>
            <form onSubmit={formSubmitHandler}>

                <PopupListBox 
                    className={`${errorModalCtx.errorFromComp.includes('permanentAddressType')? wrongInput : ''}`}
                    label='Permanent Address Type'
                    name='permanentAddressType'
                    popupListData={PERMANENT_ADDRESS}
                    defaultOption={addressState.permanentAddressType}
                    onChange={handelAddressChange}
                    required
                /> 

                <AddressInput
                    label='Permanent Address'
                    labelSpan='(For Agreement)'
                    name='permanentAddress'
                    id='permanentAddress'
                    value={addressState.permanentAddress}
                    disabled={addressState.permanentAddressDisabled}
                    required
                />

                <div className={formActionsCN}>
                    <Button
                        type='button'
                        className='negative'
                        onClick={handelViewChange}
                        dataAttributes={{'data-change-state-to': 'PREVIOUS'}}
                    >
                        <i className="lni lni-angle-double-left" data-change-state-to='PREVIOUS'></i>
                        Previous
                    </Button>
                    <Button
                        type='submit'
                        className='default'
                    >
                        Next
                        <i className="lni lni-angle-double-right" ></i>
                    </Button>
                </div>
            </form>
        </>
    );
}

export default BFPage5;

function addressReducer( state, action ){
    const processedAddress = { ...state };


    if( action.type === 'PERMANENT_ADDRESS_TYPE' )
        processedAddress.permanentAddressType = action.value;

    switch (processedAddress.permanentAddressType) {
        case 'Same As Present Address':
            processedAddress.permanentAddress = processedAddress.presentAddress;
            processedAddress.permanentAddressDisabled = true;
        break;

        case 'Different':
            processedAddress.permanentAddress = (processedAddress.permanentAddress !== processedAddress.presentAddress)? processedAddress.permanentAddress : '';
            processedAddress.permanentAddressDisabled = false;
        break;
    
        default:
            processedAddress.permanentAddress = '';
            processedAddress.permanentAddressDisabled = true;
        break;
    }

    return processedAddress;
}