export const LABEL_CHAR_MAX_LIMIT = 34;
export const MAX_UPLOAD_SIZE = 10 * 1024; // MB
export const IMG_ACCEPTED_FILE_FORMAT = ['image/png', 'image/jpeg', 'image/jpg'];
export const DOC_ACCEPTED_FILE_FORMAT = ['application/pdf'];
export const IMG_DOC_ACCEPTED_FILE_FORMAT = [...IMG_ACCEPTED_FILE_FORMAT, ...DOC_ACCEPTED_FILE_FORMAT];


export function isEmpty(value) {
    return value === undefined || value === null  || 
            ( typeof value === 'object' && Object.keys(value).length === 0 ) || 
            ( typeof value === 'string' && value.trim().length === 0 );
}

export function calculateLableLength(props){
    if( !props.hideLabel ){
        return ( 
            props.label.length + 
            ( (!!props.labelSpan)? props.labelSpan.length : 0 ) + 
            ( (props.required)? 2 : 0) 
        )
    }
    return 0;
}