const reportWebVitals = onPerfEntry => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    import('web-vitals').then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
      getCLS(onPerfEntry);
      getFID(onPerfEntry);
      getFCP(onPerfEntry);
      getLCP(onPerfEntry);
      getTTFB(onPerfEntry);
    });
  }
};

export default reportWebVitals;


// Functional Imports
// import { useEffect, useReducer, useState } from 'react';
// import { errorModalReducer, addressReducer } from './BFPage4_Helper';

// // Components Import
// import InputBox, { getValueFromInputBoxByName } from '../../../UI/InputBox/InputBox';
// import PopupListBox, { fetchSelectedOption } from '../../../UI/PopupListBox/PopupListBox';
// import Button from '../../../UI/Button/Button';
// import Modal from '../../../UI/ShowModal/ShowModal';

// // Styled Imports
// import formUtilityStyles from '../../BookingForm.module.css';


// const { 'form-actions' : formActionsCN, 'wrong-input': wrongInput } = formUtilityStyles;

// const PERMANENT_ADDRESS = ['Select One', 'Same As Present Address', 'Different'];
// const CO_APPLICANT_OPTIONS = ['Select One', 'Yes', 'No'];


// function BFPage4(props) {

//     const [ errorState, dispatchErrorState ] = useReducer(
//         errorModalReducer,  { display: false, title: '', message: '', className: [] }
//     );

//     const [ addressState, dispatchAddress ] = useReducer(
//         addressReducer, { 
//             presentAddress: (props?.filledValues?.presentAddress)? props.filledValues.presentAddress : '', 
//             permanentAddressType: (props?.filledValues?.permanentAddressType)? props.filledValues.permanentAddressType : '', 
//             permanentAddress: (props?.filledValues?.permanentAddress)? props.filledValues.permanentAddress : '', 
//             permanentAddressDisabled: true 
//         }
//     );

//      const [ submitButtonState, setSubmitButtonState ] = useState('Submit');

//     useEffect(() => { dispatchAddress({ type: 'RE-EVALUATE' }) }, []); 

//     function handelViewChange(event){
//         props.changeView( event.target.getAttribute('data-change-state-to') );
//     }

//     function handelAddressChange(event){
//         if( event.target.name === 'presentAddress' )
//             dispatchAddress({ type: 'PRESENT_ADDRESS', value: event.target.value })

//         if( event.target.name === 'permanentAddressType' )
//             dispatchAddress({ type: 'PERMANENT_ADDRESS_TYPE', value: event.target.value })

//         if( event.target.name === 'permanentAddress' )
//             dispatchAddress({ type: 'PERMANENT_ADDRESS', value: event.target.value })
//     }

//     function handelCoApplicantChange(event){
//         const selectedValue = event.target.value;

//         if( selectedValue === 'Yes' ){
//             setSubmitButtonState('Next');
//             return;
//         }

//         setSubmitButtonState('Submit');
//     }

//     function formSubmitHandler(event){
//         event.preventDefault();
        
//         const presentAddress = getValueFromInputBoxByName(event, 'presentAddress');
//         const permanentAddressType = fetchSelectedOption(event, 'permanentAddressType').selectedValue;
//         const permanentAddress = getValueFromInputBoxByName(event, 'permanentAddress');
        // const coApplicant = fetchSelectedOption(event, 'coApplicant').selectedValue;

//         if( presentAddress.length < 1 ){
//             dispatchErrorState({ type: 'NEW_ERROR', name: 'presentAddress', title: 'Invalid Input!', message: 'Please enter a valid address' });
//             return; 
//         }

//         if( permanentAddressType === 'Select One' ){
//             dispatchErrorState({ type: 'NEW_ERROR', name: 'permanentAddressType', title: 'Invalid Input!', message: 'Please select a valid permanent address type' });  
//             return;  
//         }

//         if( permanentAddress.length < 1 ){
//             dispatchErrorState({ type: 'NEW_ERROR', name: 'permanentAddress', title: 'Invalid Input!', message: 'Please enter a valid address' });
//             return;  
//         }

        // if( coApplicant === 'Select One' ){
        //     dispatchErrorState({ type: 'NEW_ERROR', name: 'coApplicant', title: 'Invalid Input!', message: 'Please select a valid option' });    
        //     return;  
        // }

//         const payLoad = { presentAddress, permanentAddressType, permanentAddress,  };
//         props.formSubmitHandler(payLoad);
// }

//     function closeModal(){
//         dispatchErrorState({ type: 'CLOSE_MODAL'} );
//     }

//     console.log( props.filledValues );

//     return (
//         <>
//             <form onSubmit={formSubmitHandler}>
//                 <InputBox 
//                     className={errorState.className.includes('presentAddress') && wrongInput}
//                     label='Present Address'
//                     type='text' 
//                     name='presentAddress'
//                     id='presentAddress'
//                     value={addressState.presentAddress}
//                     onChange={handelAddressChange}
//                     required
//                 />

//                 <PopupListBox 
//                     className={errorState.className.includes('permanentAddressType') && wrongInput}
//                     label='Permanent Address Type'
//                     name='permanentAddressType'
//                     popupListData={PERMANENT_ADDRESS}
//                     defaultOption={addressState.permanentAddressType}
//                     onChange={handelAddressChange}
//                     required
//                 />

//                 <InputBox 
//                     className={errorState.className.includes('permanentAddress') && wrongInput}
//                     label='Permanent Address'
//                     labelSpan='(For Aggrement)' 
//                     name='permanentAddress'
//                     id='permanentAddress'
//                     type='text'
//                     onChange={handelAddressChange}
//                     value={addressState.permanentAddress}
//                     required 
//                     disabled={addressState.permanentAddressDisabled}
//                 />

//                 <PopupListBox 
//                     className={errorState.className.includes('coApplicant') && wrongInput}
//                     label='Want To Add A Co-Applicant ?'
//                     name='coApplicant'
//                     popupListData={CO_APPLICANT_OPTIONS}
//                     defaultOption={(props?.filledValues?.coApplicant)? props.filledValues.coApplicant : ''}
//                     onChange={handelCoApplicantChange}
//                     required
//                 /> 

//                 <InputBox 
//                     className={errorState.className.includes('profession') && wrongInput}
//                     label='Profession'
//                     type='text' 
//                     name='profession'
//                     id='profession'
//                     value={props.filledValues.profession}
//                     required
//                 />

//                 <div className={formActionsCN}>
//                     <Button
//                         type='button'
//                         className='negative'
//                         onClick={handelViewChange}
//                         dataAttributes={{'data-change-state-to': 'PREVIOUS'}}
//                     >
//                         <i className="lni lni-angle-double-left" data-change-state-to='PREVIOUS'></i>
//                         Previous
//                     </Button>
//                     <Button
//                         type='submit'
//                         className='default'
//                     >
//                         {submitButtonState}
                        
//                         {submitButtonState === 'Submit' && <i className="lni lni-upload"></i>}
//                         {submitButtonState !== 'Submit' && <i className="lni lni-angle-double-right" ></i>}
//                     </Button>
//                 </div>
//             </form>
//             {errorState.display && <Modal title={errorState.title} message={errorState.message} closeModal={closeModal}/>}
//         </>
//     );
// }

// export default BFPage4;