// Functional Imports
import React, { useState } from 'react';
import { calculateLableLength, LABEL_CHAR_MAX_LIMIT } from '../../../Utility/Utility';

// Styled Import
import styles from './PopupListBox.module.css';

function PopupListBox(props){

    const [selectedOption, setSelectedOption] = useState((!!props.defaultOption)? props.defaultOption : 0);
    const [isDisabled, disabledClassName] = [ !!props.disabled, styles['disabled'] ];

    function handelOnChange(event) {
        if( isDisabled ) return;
        props.onChange && props.onChange(event);
        setSelectedOption( parseInt(event.target.getAttribute('data-index')) );
    }

    if( !props.popupListData || props.popupListData.length < 0 ){
        throw new Error('Popuplist Structure Is Incomplete!');
    }

    const selectedOptionClassName = styles['selected-option'];
    const name = props.name;
    const radioInputs = [];
    const labelNames = [];

    
    let labelClassNames = (!!props.className)? `${props.className} ` : '';
    let popupListBoxClassNames = ((!!props.className)? `${props.className} ` : '') + styles[`popup-list-box`];

    if( props.required ){
        labelClassNames += styles['required'];
    }

    if( isDisabled ){
        labelClassNames += ` ${disabledClassName} `;
    }

    if( calculateLableLength(props) > LABEL_CHAR_MAX_LIMIT ){
        throw new Error(`"${props.label} ${props.labelSpan} - ${calculateLableLength(props)}" Label length greater than ${LABEL_CHAR_MAX_LIMIT}`)
    }
    
    props.popupListData.forEach( ( listData, index ) => {
        const id = `${props.name || ''}${listData.split(' ').join('')}_${Math.floor( Math.random() * 100 )}`;
        const checked = (index === selectedOption || listData === selectedOption);

        radioInputs.push(
            <div className={styles[`popup-list-box__value`]} key={id}>
                <input 
                    className={styles[`popup-list-box__input`]}
                    type="radio"
                    id={id}
                    value={listData}
                    name={name}
                    checked={index === 0 || checked} 
                    data-index={index}
                    onChange={handelOnChange}
                    required={props.required}
                    disabled={isDisabled}
                />
                <p className={styles[`popup-list-box__input-text`]}>{listData}</p>
            </div>
        );

        labelNames.push(
            <li key={id}>
                <label 
                    className={`${styles[`popup-list-box__option`]} ${(index === selectedOption || listData === selectedOption)? selectedOptionClassName:''}`}
                    htmlFor={id}
                    aria-hidden="true"
                >
                    {listData}
                </label>
            </li>
        );
    })

    return (
        <div className={styles[`popup-list-box-container`]}>
            { 
                !props.hideLabel && 
                <label 
                    className={`${styles['pop-up-label']} ${labelClassNames}`} 
                    htmlFor={props.id}
                >
                    {props.label}
                    {props.labelSpan && <span>{props.labelSpan}</span>}
                </label>
            }
            <div className={popupListBoxClassNames}>
                <div className={`${(isDisabled)? disabledClassName : styles[`popup-list-box__current`]}`} tabIndex='-1'>
                    {radioInputs}
                    {/* <img className={styles[`popup-list-box__icon`]} src="http://cdn.onlinewebfonts.com/svg/img_295694.svg" alt="Arrow Icon" aria-hidden="true" /> */}
                    <i className={`lni lni-chevron-up-circle ${styles[`popup-list-box__icon`]}`}></i>
                </div>
                <div className={styles[`popup-list-box__list-container`]}>
                    <ul className={styles[`popup-list-box__list`]}>
                        {labelNames}
                    </ul>
                </div>
            </div>
        </div>
    );
}

function fetchSelectedOption(event, name){
    const target = event.target.querySelectorAll(`[name="${name}"][type='radio']`);
    for( const elem of target ) { 
        if( elem.checked ){
            return { selectedValue: elem.value, index: elem.dataset.index };
        }
    }
    return { selectedValue: '', index: '' };

}

export default PopupListBox;
export { fetchSelectedOption };