// Functional Imports
import { useContext } from 'react';
import ErrorModalContext from '../../../../context/error-modal-context/error-modal-context';

// Components Import
import InputBox, { getValueFromInputBoxByName } from '../../../UI/InputBox/InputBox';
import PopupListBox, { fetchSelectedOption } from '../../../UI/PopupListBox/PopupListBox';
import Button from '../../../UI/Button/Button';

// Styled Imports
import formUtilityStyles from '../../BookingForm.module.css';


const { 'form-actions' : formActionsCN, 'wrong-input': wrongInput } = formUtilityStyles;


const GENDERS = ['Select One', 'Male', 'Female', 'Other'];
const NATIONALITIES = ['Select One', 'Indian', 'NRI']


function BFPage2(props) {

    const errorModalCtx = useContext(ErrorModalContext);

    function handelViewChange(eventOrState){ 
        const viewState = eventOrState?.target?.getAttribute('data-change-state-to') || eventOrState;
        props.changeView(viewState);
    }

    async function formSubmitHandler(event){
        event.preventDefault();

        const fullName = getValueFromInputBoxByName(event, 'fullName');
        const dateOfBirth = new Date( getValueFromInputBoxByName(event, 'dateOfBirth') );
        const genderSelectOption = fetchSelectedOption(event, 'gender');
        const nationalitySelectOption = fetchSelectedOption(event, 'nationality');

        if( fullName.length < 1 ){
            errorModalCtx.showError('Invalid Input!', 'Please enter a valid name.', ['fullName'] );
            return;
        }

        if( dateOfBirth === 'Invalid Date' || dateOfBirth.getFullYear() < 1900 || dateOfBirth.getFullYear() >= ( new Date().getFullYear() ) ){
            errorModalCtx.showError('Invalid Input!', 'Please select a valid date.', ['dateOfBirth'] );
            return;
        }

        if( genderSelectOption.selectedValue === 'Select One' ){
            errorModalCtx.showError('Invalid Input!', 'Please select any gender.', ['gender'] );
            return;
        }

        if( nationalitySelectOption.selectedValue === 'Select One' ){
            errorModalCtx.showError('Invalid Input!', 'Please select any nationality.', ['nationality'] );
            return;
        }

        errorModalCtx.clearCompErrorList();

        const payLoad = { 
            fullName, 
            dateOfBirth: getValueFromInputBoxByName(event, 'dateOfBirth'), 
            gender: genderSelectOption.selectedValue, 
            nationality: nationalitySelectOption.selectedValue 
        };

        if(await props.formSubmitHandler(payLoad)) handelViewChange('NEXT')
    }

    return (
        <>
            <form onSubmit={formSubmitHandler}>

                <InputBox 
                    className={`${(errorModalCtx.errorFromComp.includes('fullName'))? wrongInput : ''}`}
                    label='Full Name'
                    labelSpan='(As Per Aadhaar)' 
                    name='fullName'
                    id='fullName'
                    type='text'
                    value={props.filledValues.fullName}
                    required 
                />

                <InputBox 
                    className={`${(errorModalCtx.errorFromComp.includes('dateOfBirth'))? wrongInput : ''}`}
                    type='date' 
                    label='Date Of Birth'
                    name='dateOfBirth'
                    id='dateOfBirth'
                    value={props.filledValues.dateOfBirth}
                    required
                />

                <PopupListBox 
                    className={`${(errorModalCtx.errorFromComp.includes('gender'))? wrongInput : ''}`}
                    label='Gender'
                    name='gender'
                    popupListData={GENDERS}
                    defaultOption={props.filledValues.gender}
                    required
                />

                <PopupListBox 
                    className={`${(errorModalCtx.errorFromComp.includes('nationality'))? wrongInput : ''}`}
                    label='Nationality'
                    name='nationality'
                    popupListData={NATIONALITIES}
                    defaultOption={props.filledValues.nationality}
                    required
                />


                <div className={formActionsCN}>
                    <Button
                        type='button'
                        className='negative'
                        onClick={handelViewChange}
                        dataAttributes={{'data-change-state-to': 'PREVIOUS'}}
                    >
                        <i className="lni lni-angle-double-left" data-change-state-to='PREVIOUS'></i>
                        Previous
                    </Button>
                    <Button
                        type='submit'
                        className='default'
                    >
                        Next
                        <i className="lni lni-angle-double-right" ></i>
                    </Button>
                </div>
            </form>
        </>
    );
}

export default BFPage2;