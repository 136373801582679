// Functional Imports
import { useState, createContext } from "react";

// Components Import
import ShowModal from "../../components/UI/ShowModal/ShowModal";


// Error Modal Context
const ErrorModalContext = createContext({ 
    display: false, 
    title: '', 
    message: '', 
    errorFromComp: [], 
    showError: ( title, message, errorFromComp = [] ) => {},
    closeError: () => {},
    clearCompErrorList: () => {}
});

// Error Modal Provider
function ErrorModalContextProvider(props){
    const [ errorModalProviderValue, setErrorModalProviderValue ] = useState({ display: false, title: '', message: '', errorFromComp: [] });

    function showError( title, message, errorFromComp = [] ){
        setErrorModalProviderValue({ display: true, title, message, errorFromComp });
    }

    function closeError(){
        setErrorModalProviderValue({ display: false, title: '', message: '', errorFromComp: errorModalProviderValue.errorFromComp });
    }

    function clearCompErrorList(){
        setErrorModalProviderValue({ display: false, title: '', message: '', errorFromComp: [] });
    }

    return (
        <ErrorModalContext.Provider value={{ ...errorModalProviderValue, showError, closeError, clearCompErrorList }}>
            {props.children}
            {errorModalProviderValue.display && <ShowModal title={errorModalProviderValue.title} message={errorModalProviderValue.message} closeModal={closeError} />}
        </ErrorModalContext.Provider>
    );
}

// Exports
export default ErrorModalContext;
export { ErrorModalContextProvider };