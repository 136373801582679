// Functional Imports
import { useReducer } from 'react'

// Components Import
import InputBox, { getValueFromInputBoxByName } from '../../../UI/InputBox/InputBox';
import PopupListBox, { fetchSelectedOption } from '../../../UI/PopupListBox/PopupListBox';
import Button from '../../../UI/Button/Button';
import Modal from '../../../UI/ShowModal/ShowModal';

// Styled Imports
import formUtilityStyles from '../../BookingForm.module.css';


const { 'form-actions' : formActionsCN, 'wrong-input': wrongInput } = formUtilityStyles;


const GENDERS = ['Select One', 'Male', 'Female', 'Other'];
const NATIONALITIES = ['Select One', 'Indian', 'NRI']

function errorModalReducer( state, action ){
    if( action.type === 'NEW_ERROR' )
        return { display: true, title: action.title, message: action.message, errorComp: [ action.name ] }

    if( action.type === 'CLOSE_MODAL' )
        return { modalDisplay: false, title: '', message: '', errorComp: [...state.errorComp]}
}

function BFPage11(props) {

    const [ errorState, dispatchErrorState ] = useReducer(
        errorModalReducer,  { display: false, title: '', message: '', errorComp: [] }
    );

    function handelViewChange(eventOrState){ 
        const viewState = eventOrState?.target?.getAttribute('data-change-state-to') || eventOrState;
        props.changeView(viewState);
    }

    async function formSubmitHandler(event){
        event.preventDefault();

        const coApp_FullName = getValueFromInputBoxByName(event, 'coApp_FullName');
        const coApp_dateOfBirth = new Date( getValueFromInputBoxByName(event, 'coApp_dateOfBirth') );
        const coApplicantGenderSelectOption = fetchSelectedOption(event, 'coApp_gender');
        const coApplicantNationalitySelectOption = fetchSelectedOption(event, 'coApp_nationality');

        if( coApp_FullName.length < 1 ){
            dispatchErrorState({ type: 'NEW_ERROR', name: 'coApp_FullName', title: 'Invalid Input!', message: 'Please enter a valid name.' });
            return;
        }

        if( coApp_dateOfBirth === 'Invalid Date' || coApp_dateOfBirth.getFullYear() < 1900 || coApp_dateOfBirth.getFullYear() >= ( new Date().getFullYear() ) ){
            dispatchErrorState({ type: 'NEW_ERROR', name: 'coApp_dateOfBirth', title: 'Invalid Input!', message: 'Please select a valid date.' });
            return;
        }

        if( coApplicantGenderSelectOption.selectedValue === 'Select One' ){
            dispatchErrorState({ type: 'NEW_ERROR', name: 'coApp_gender', title: 'Invalid Input!', message: 'Please select any gender.' });
            return;
        }

        if( coApplicantNationalitySelectOption.selectedValue === 'Select One' ){
            dispatchErrorState({ type: 'NEW_ERROR', name: 'coApp_nationality', title: 'Invalid Input!', message: 'Please select any nationality.' });
            return;
        }

        const payLoad = { 
            coApp_FullName, 
            coApp_dateOfBirth: getValueFromInputBoxByName(event, 'coApp_dateOfBirth'), 
            coApp_gender: coApplicantGenderSelectOption.selectedValue, 
            coApp_nationality: coApplicantNationalitySelectOption.selectedValue 
        };

        if(await props.formSubmitHandler(payLoad)) handelViewChange('NEXT')
    }

    function closeModal(){
        dispatchErrorState({ type: 'CLOSE_MODAL'} );
    }
    
    return (
        <>
            <form onSubmit={formSubmitHandler}>

                <InputBox 
                    className={errorState.errorComp.includes('coApp_FullName') && wrongInput}
                    label='Full Name'
                    labelSpan='(Co-Applicant)'
                    name='coApp_FullName'
                    id='coApp_FullName'
                    type='text'
                    value={props.filledValues.coApp_FullName}
                    required 
                />

                <InputBox 
                    className={errorState.errorComp.includes('coApp_dateOfBirth') && wrongInput}
                    type='date' 
                    label='Date Of Birth'
                    labelSpan='(Co-Applicant)'
                    name='coApp_dateOfBirth'
                    id='coApp_dateOfBirth'
                    value={props.filledValues.coApp_dateOfBirth}
                    required
                />

                <PopupListBox 
                    className={errorState.errorComp.includes('coApp_gender') && wrongInput}
                    label='Gender'
                    labelSpan='(Co-Applicant)'
                    name='coApp_gender'
                    popupListData={GENDERS}
                    defaultOption={props.filledValues.coApp_gender}
                    required
                />

                <PopupListBox 
                    className={errorState.errorComp.includes('coApp_nationality') && wrongInput}
                    label='Nationality'
                    labelSpan='(Co-Applicant)'
                    name='coApp_nationality'
                    popupListData={NATIONALITIES}
                    defaultOption={props.filledValues.coApp_nationality}
                    required
                />


                <div className={formActionsCN}>
                    <Button
                        type='button'
                        className='negative'
                        onClick={handelViewChange}
                        dataAttributes={{'data-change-state-to': 'PREVIOUS'}}
                    >
                        <i className="lni lni-angle-double-left" data-change-state-to='PREVIOUS'></i>
                        Previous
                    </Button>
                    <Button
                        type='submit'
                        className='default'
                    >
                        Next
                        <i className="lni lni-angle-double-right" ></i>
                    </Button>
                </div>
            </form>
            {errorState.display && <Modal title={errorState.title} message={errorState.message} closeModal={closeModal}/>}
        </>
    );
}

export default BFPage11;