// Functional Imports
import React, { useState, useEffect, useContext } from "react";
import { isEmpty } from "../../Utility/Utility";
import ErrorModalContext from "../../context/error-modal-context/error-modal-context";


// Components Import
import Loader from "../UI/Loader/Loader";
import BFHeader from "./BFHeader/BFHeader";
import BFPriceSheet from "./BFPriceSheet/BFPriceSheet";
import BFInstructions from "./BFInstructions/BFInstructions";
import BFUserInputForm from './BFUserInputForm/BFUserInputForm';
import BFEmailVerification from "./BFEmailVerification/BFEmailVerification";

const BACKEND_URL = 'https://backend.concorde.in:8080'; // https://backend.concorde.in:8080

function BookingForm( props ){
    const [urlEndpointWithAccessToken, setUrlEndpointWithAccessToken] = useState({ url: null, accessToken: null, bookingFormId: null });
    const [hiddenEmail, setHiddenEmail] = useState('');
    const [displayLoader, setDisplayLoader] = useState(true);
    const [currentView, setCurrentView] = useState(null);
    const [bookingFormData, setBookingFormData] = useState({});
    const [alternateHeaderInfo, setAlternateHeaderInfo] = useState({ header: null, body: null });
    const errorModalCtx = useContext(ErrorModalContext);
    const [refreshState, setRefreshState] = useState(-1);


    useEffect(() => {
        const url = new URL(document.location);
        const bookingFormId = url.searchParams.get('f');

        if(!bookingFormId){
            setAlternateHeaderInfo(({ header: 'Error', body: 'Booking form id cannot be empty' }))
            setDisplayLoader(false)
            return 
        } 
        
        setUrlEndpointWithAccessToken({ url: BACKEND_URL + '/U/booking-form', accessToken: null, bookingFormId })
    }, [])

    // Fetch 
    useEffect(() => {
        (async () => {
            try{
                if(!urlEndpointWithAccessToken.url) return;
                const urlWithBFId = urlEndpointWithAccessToken.url + '/' + urlEndpointWithAccessToken.bookingFormId + '/email-verify';
                const method = 'GET';

                const response = await fetch(urlWithBFId, { method, credentials: 'include' });

                if(!response.ok){
                    if(response.headers.get('Content-Type').includes('application/json')){
                        const errResponse = await response.json();
                        throw errResponse;
                    }
                }

                const responsePayload = await response.json();

                if('accessToken' in responsePayload){
                    setUrlEndpointWithAccessToken(prev => ({ ...prev, accessToken: responsePayload.accessToken }));
                }

                if('email' in responsePayload){
                    setHiddenEmail(responsePayload.email);
                    setCurrentView(0);
                }
            } catch(err) {
                setAlternateHeaderInfo({ header: 'Request Failed!', body: err.msg || 'Verification failed' })
            }
            setDisplayLoader(false);
        })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [urlEndpointWithAccessToken.url])

    useEffect(() => {
        if(!urlEndpointWithAccessToken.accessToken) return;

        setDisplayLoader(true);
        (async () => {
            try{
                const urlWithBFId = urlEndpointWithAccessToken.url + '/view?accessToken=' + urlEndpointWithAccessToken.accessToken;
                const method = 'GET';

                const response = await fetch(urlWithBFId, { method, credentials: 'include' });

                if(!response.ok){
                    if(response.headers.get('Content-Type').includes('application/json')){
                        const errResponse = await response.json();
                        throw errResponse;
                    }
                }

                const responsePayload = await response.json();

                if(responsePayload.altMsg && responsePayload.altMsg === 'Response submitted!'){
                    setAlternateHeaderInfo({ header: responsePayload.altMsg, body: responsePayload.msg });
                    setDisplayLoader(false);
                    return
                }

                setBookingFormData(responsePayload);
                changeCurrentView('NEXT');
                setDisplayLoader(false);
            } catch( err ) {
                setAlternateHeaderInfo({ header: 'Request Failed!', body: err.msg || 'Verification failed' })
                setDisplayLoader(false);
            }
        })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [urlEndpointWithAccessToken.accessToken, refreshState])

    function setAccessToken(token){
        if(isEmpty(token)) return setAlternateHeaderInfo('Authorization Failed!', 'Failed to authorize user. Try refreshing the page')
        setUrlEndpointWithAccessToken(prev => ({ ...prev, accessToken: token }));
    }

    function changeCurrentView(state){
        if(state === 'PREVIOUS') setCurrentView(currentView - 1);
        if(state === 'NEXT') setCurrentView(currentView + 1);
    }

    async function formSubmitHandler(payload){
        try{
            setBookingFormData( prevState => ({ ...prevState, ...payload }) );
            const url = urlEndpointWithAccessToken.url + '/submit?accessToken=' + urlEndpointWithAccessToken.accessToken;
            const method = 'POST';
            const headers = { 'content-type': 'application/json' };
            const body = JSON.stringify(payload);
            setDisplayLoader(true);

            const response = await fetch(url, { method, headers, body, credentials: 'include' });

            if(response.status === 410){
                const errMsg = response.headers.get('Content-Type').includes('application/json')? (await response.json()).msg : 'Link has been expired!'
                setAlternateHeaderInfo({ header: 'Request Failed!', body: errMsg || 'Link has been Expired!' });
                setDisplayLoader(false);
                return
            }

            if(!response.ok){
                if(response.headers.get('Content-Type').includes('application/json')){
                    const errResponse = await response.json();
                    throw errResponse;
                }
            }

            setDisplayLoader(false);

            if('DOCS_TO_UPLOAD' in payload){
                const newFile = {};
                let updateBookingForm = false;

                if('PAYMENT_COPY' in payload.DOCS_TO_UPLOAD && !isEmpty(payload.DOCS_TO_UPLOAD.PAYMENT_COPY?.fileName)){
                    updateBookingForm = true;
                    newFile['paymentCopyLocation'] = payload.DOCS_TO_UPLOAD.PAYMENT_COPY.fileName;
                } 

                if('AADHAR_COPY' in payload.DOCS_TO_UPLOAD && !isEmpty(payload.DOCS_TO_UPLOAD.AADHAR_COPY?.fileName)){
                    updateBookingForm = true;
                    newFile['aadharCopyLocation'] = payload.DOCS_TO_UPLOAD.AADHAR_COPY.fileName;
                } 

                if('PAN_COPY' in payload.DOCS_TO_UPLOAD && !isEmpty(payload.DOCS_TO_UPLOAD.PAN_COPY?.fileName)){
                    updateBookingForm = true;
                    newFile['panCopyLocation'] = payload.DOCS_TO_UPLOAD.PAN_COPY.fileName;
                } 

                if('APPLICANT_PHOTO' in payload.DOCS_TO_UPLOAD && !isEmpty(payload.DOCS_TO_UPLOAD.APPLICANT_PHOTO?.fileName)){
                    updateBookingForm = true;
                    newFile['photoOfApplicantLocation'] = payload.DOCS_TO_UPLOAD.APPLICANT_PHOTO.fileName;
                } 

                if('CO_APPLICANT_PHOTO' in payload.DOCS_TO_UPLOAD && !isEmpty(payload.DOCS_TO_UPLOAD.CO_APPLICANT_PHOTO?.fileName)){
                    updateBookingForm = true;
                    newFile['coApp_photoOfApplicantLocation'] = payload.DOCS_TO_UPLOAD.CO_APPLICANT_PHOTO.fileName;
                } 

                if('agreeToSourceInfo' in payload){
                    updateBookingForm = true;
                    newFile['agreeToSourceInfo'] = payload.agreeToSourceInfo;
                } 

                if(updateBookingForm){
                    setBookingFormData(prev => ({ ...prev, ...newFile }))
                }
            }

            return true;
        } catch( err ){
            setDisplayLoader(false);
            errorModalCtx.showError('Request Failed!', err.msg || 'Failed to process submitted data!' );
            return false;
        }
    }

    function userFileUnselectHandler(event){
        const unsetUserFileSelection = event.target.dataset.name;
        if(!unsetUserFileSelection || !bookingFormData[unsetUserFileSelection]) return;
        setBookingFormData( prevState => ({ ...prevState, [unsetUserFileSelection]: '' }) );
    }

    function refreshData(){
        setRefreshState(prev => ++prev);
    }

    const BOOKING_FORM_VIEWS = {
        0: {
            view: (
                <BFEmailVerification 
                    setDisplayLoader={setDisplayLoader} 
                    urlEndpoint={urlEndpointWithAccessToken.url}
                    bookingFormId={urlEndpointWithAccessToken.bookingFormId}
                    setAccessToken={setAccessToken}
                    hiddenEmail={hiddenEmail}
                />
            ),
            currentViewInfo: 'Email Verification'
        },
        1: {
            view: (
                <BFPriceSheet 
                    changeView={changeCurrentView} 
                    bookingFormData={bookingFormData} 
                    urlEndpointWithAccessToken={urlEndpointWithAccessToken} 
                    setAlternateHeaderInfo={setAlternateHeaderInfo}
                    setDisplayLoader={setDisplayLoader}
                />
            ),
            currentViewInfo: 'Price Sheet'
        },
        2: {
            view: ( 
                <BFInstructions 
                    changeView={changeCurrentView} 
                    bookingFormData={bookingFormData} 
                    tacAgreeHandler={formSubmitHandler} 
                />
            ),
            currentViewInfo: 'Instructions'
        },
        3: {
            view: (
                <BFUserInputForm 
                    changeParentView={changeCurrentView} 
                    filledValues={bookingFormData} 
                    formSubmitHandler={formSubmitHandler} 
                    userFileUnselectHandler={userFileUnselectHandler}
                    setDisplayLoader={setDisplayLoader}
                    refreshData={refreshData}
                />
            ),
            currentViewInfo: 'Booking Form'
        }
    }

    return (
        <>
            <BFHeader 
                currentViewInfo={
                    alternateHeaderInfo.header || 
                    ((currentView !== null)? BOOKING_FORM_VIEWS[currentView].currentViewInfo : '')
                } 
            />

            { 
                alternateHeaderInfo.body === null && 
                currentView !== null && 
                BOOKING_FORM_VIEWS[currentView].view 
            }

            { 
                alternateHeaderInfo.body !== null && 
                <h2 style={{ textAlign: 'center' }}>{alternateHeaderInfo.body}</h2> 
            }

            <Loader  display={displayLoader} />
        </>
    );
}

export default BookingForm;