// Functional Import
import React, { useReducer, useEffect } from 'react';
import { isEmpty } from '../../../Utility/Utility';
import { calculateLableLength, LABEL_CHAR_MAX_LIMIT } from '../../../Utility/Utility';

// Components Import
import InputBox from '../InputBox/InputBox';

// Styles Import
import styles from './AddressInput.module.css';

const { 
    'address-main-container': addressMainContainer, 
    'address-container': addressContainer,
    'two-input-container': twoInputContainer,
    'wrong-input': wrongInput,
    disabled,
    required
} = styles;

function addressReducer( state, action ){
    const address = {...state};

    if( action.type === 'NEW_ENTRY' )
        address[action.name] = action.value.trim();


    if( action.type === 'DATA_FROM_PARENT' ){
        address.addressLine1 = (action?.value?.addressLine1)? action.value.addressLine1 : '';
        address.addressLine2 = (action?.value?.addressLine2)? action.value.addressLine2 : '';
        address.country      = (action?.value?.country)?      action.value.country : '';
        address.state        = (action?.value?.state)?        action.value.state : '';
        address.city         = (action?.value?.city)?         action.value.city : '';
        address.pincode      = (action?.value?.pincode)?      action.value.pincode : '';
        address.required     = (action?.required)?            action.required : false;
        address.disabled     = (action?.disabled)?            action.disabled : false;
    }

    if( action.type === 'ERROR_CHECK' ){ 
        if( action.required && ['addressLine1', 'country', 'state', 'city', 'pincode'].includes(action.name) && isEmpty( address[action.name] ) ){
            address.errorsList.add(action.name);
        } else {
            address.errorsList.delete(action.name);
        }
    }

    if( !address.required ){
        if( !isEmpty(address.addressLine1) || !isEmpty(address.addressLine2) || !isEmpty(address.country) || 
            !isEmpty(address.state) || !isEmpty(address.city) || !isEmpty(address.pincode)
        ){
            address.fieldsRequired = true;
        } else {
            address.errorsList = new Set();
            address.fieldsRequired = false;
        }
    }

    address.errorsList.forEach( errorClassName => {
        !isEmpty( address[errorClassName] ) && address.errorsList.delete(errorClassName)
    });
    
    return address
}

function AddressInput(props){

    const [ addressState, dispatchAddress ] = useReducer(addressReducer, { 
        addressLine1: '', addressLine2: '', country: '', state: '', city: '', pincode: '', 
        errorsList: new Set(), required: true, fieldsRequired: true, disabled: false 
    });

    useEffect(() => { 
        dispatchAddress({ type: 'DATA_FROM_PARENT', value: props.value, required: !!props.required, disabled: !!props.disabled }) 
    }, [props.value, props.required, props.disabled] );

    if( calculateLableLength(props) > LABEL_CHAR_MAX_LIMIT ){
        throw new Error(`"${props.label} ${props.labelSpan} - ${calculateLableLength(props)}" Label length greater than ${LABEL_CHAR_MAX_LIMIT}`)
    }

    function handelUserInput(event){
        const name  = event.target.name.slice( event.target.name.lastIndexOf('.') + 1 );
        const value = event.target.value;
        const type  = 'NEW_ENTRY';

        dispatchAddress({ type, name, value });
    }

    function inputBlurHandler(event){
        const name      = event.target.name.slice( event.target.name.lastIndexOf('.') + 1 );
        const required  = event.target.required; 
        const type      = 'ERROR_CHECK';

        dispatchAddress({ type, required, name })
    }

    return (
        <div className={`${(addressState.errorsList.size > 0)? wrongInput : ''} ${addressMainContainer} ${(!!props.disabled)? disabled : ''}`}>
            { 
                !props.hideLabel && 
                <label 
                    className={`${(addressState.errorsList.size > 0)? wrongInput : ''} ${(!!props.disabled)? disabled : ''} ${(!!props.required)? required : ''}`} 
                    htmlFor={props.id}
                >
                    {props.label}
                    {props.labelSpan && <span>{props.labelSpan}</span>}
                </label>
            }
            <div className={addressContainer}>
                <InputBox 
                    label='Address Line 1'
                    className={`${(addressState.errorsList.has('addressLine1'))? wrongInput : ''}`}
                    id={`${props.id || props.name}.addressLine1`}
                    name={`${props.name}.addressLine1`}
                    onChange={handelUserInput}
                    onBlur={inputBlurHandler}
                    value={addressState.addressLine1}
                    required={addressState.fieldsRequired}
                    disabled={addressState.disabled}
                />
                <InputBox 
                    label='Address Line 2'
                    className={`${(addressState.errorsList.has('addressLine2'))? wrongInput : ''}`}
                    id={`${props.id || props.name}.addressLine2`}
                    name={`${props.name}.addressLine2`}
                    onChange={handelUserInput}
                    value={addressState.addressLine2}
                    disabled={addressState.disabled}
                />
                <div className={twoInputContainer}>
                    <InputBox 
                        label='Country'
                        className={`${(addressState.errorsList.has('country'))? wrongInput : ''}`}
                        id={`${props.id || props.name}.country`}
                        name={`${props.name}.country`}
                        value={addressState.country}
                        onChange={handelUserInput}
                        onBlur={inputBlurHandler}
                        required={addressState.fieldsRequired}
                        disabled={addressState.disabled}
                    />
                    <InputBox 
                        label='State'
                        className={`${(addressState.errorsList.has('state'))? wrongInput : ''}`}
                        id={`${props.id || props.name}.state`}
                        name={`${props.name}.state`}
                        value={addressState.state}
                        onChange={handelUserInput}
                        onBlur={inputBlurHandler}
                        required={addressState.fieldsRequired}
                        disabled={addressState.disabled}
                    />
                </div>
                <div className={twoInputContainer}>
                    <InputBox 
                        label='City'
                        className={`${(addressState.errorsList.has('city'))? wrongInput : ''}`}
                        id={`${props.id || props.name}.city`}
                        name={`${props.name}.city`}
                        value={addressState.city}
                        onChange={handelUserInput}
                        onBlur={inputBlurHandler}
                        required={addressState.fieldsRequired}
                        disabled={addressState.disabled}
                    />
                    <InputBox 
                        label='Pincode'
                        className={`${(addressState.errorsList.has('pincode'))? wrongInput : ''}`}
                        id={`${props.id || props.name}.pincode`}
                        name={`${props.name}.pincode`}
                        value={addressState.pincode}
                        onChange={handelUserInput}
                        onBlur={inputBlurHandler}
                        required={addressState.fieldsRequired}
                        disabled={addressState.disabled}
                    />
                </div>
            </div>
        </div>
    )
}

function getAddressDataFromFormByName( formEvent, name ){
    const addressLine1  = formEvent.target.querySelector(`[name='${name}.addressLine1']`)?.value; 
    const addressLine2  = formEvent.target.querySelector(`[name='${name}.addressLine2']`)?.value;
    const country       = formEvent.target.querySelector(`[name='${name}.country']`)?.value;
    const state         = formEvent.target.querySelector(`[name='${name}.state']`)?.value;
    const city          = formEvent.target.querySelector(`[name='${name}.city']`)?.value;
    const pincode       = formEvent.target.querySelector(`[name='${name}.pincode']`)?.value;

    return { addressLine1, addressLine2, country, state, city, pincode };
}

export default AddressInput;
export { getAddressDataFromFormByName };