// Functional Imports
import { useState, useEffect, useContext } from "react";
import ErrorModalContext from "../../../context/error-modal-context/error-modal-context";

// Component Imports
import InputBox, { getValueFromInputBoxByName } from "../../UI/InputBox/InputBox";
import Button from "../../UI/Button/Button";
import ResendOTPButton from "./ResendOTPButton/ResendOTPButton";


// Styles Import
import styles from './BFEmailVerification.module.css'
import formUtilityStyles from '../BookingForm.module.css';


function EmailOTPVerification(props){
    const errorModalCtx = useContext(ErrorModalContext);

    async function formSubmitHandler(event){
        try{    
            event.preventDefault();
            
            const url = props.urlEndpoint + '/' + props.bookingFormId + '/email-verify';
            const method = 'POST';
            const headers = { 'Content-Type': 'application/json' };
            const otp = getValueFromInputBoxByName(event, 'otp');
            const body = JSON.stringify({ otp });

            props.setDisplayLoader(true);

            const response = await fetch(url, { method, headers, body, credentials: 'include' });

            if(!response.ok){
                if(response.headers.get('Content-Type').includes('application/json')){
                    const errResponse = await response.json();
                    throw errResponse;
                }
            }

            const responseJSON = await response.json();

            props.setAccessToken(responseJSON.accessToken);
        } catch(err) {
            errorModalCtx.showError('Error', err.msg || 'Request failed!');
        }
        props.setDisplayLoader(false);
    }

    return (
        <div className={styles['container']}>
            <div className={styles['container-header']}>
                <h3>OTP has been sent your email</h3>
                <p>{props.hiddenEmail}</p>
            </div>
            <div className={styles['container-body']}>
                <form onSubmit={formSubmitHandler}>
                    <InputBox 
                        label='OTP'
                        name='otp'
                        id='otp'
                        type='text'
                        required 
                    />

                    <div className={formUtilityStyles['form-actions']}>
                        <ResendOTPButton />
                        <Button
                            type='submit'
                            className='default'
                        >
                            Verify OTP
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    );
}

function EmailCrossVerification(props){
    const errorModalCtx = useContext(ErrorModalContext);

    async function formSubmitHandler(event){
        try{    
            event.preventDefault();

            const url = props.urlEndpoint + '/' + props.bookingFormId + '/email-verify';
            const method = 'POST';
            const headers = { 'Content-Type': 'application/json' };
            const email = getValueFromInputBoxByName(event, 'emailId');
            const body = JSON.stringify({ email });

            props.setDisplayLoader(true);

            const response = await fetch(url, { method, headers, body, credentials: 'include' });

            if(!response.ok){
                if(response.headers.get('Content-Type').includes('application/json')){
                    const errResponse = await response.json();
                    throw errResponse;
                }
            }

            props.setEmailOTPVerificationState();

        } catch(err) {
            errorModalCtx.showError('Error', err.msg || 'Request failed!');
        }
        props.setDisplayLoader(false);
    }

    return (
        <div className={styles['container']}>
            <div className={styles['container-header']}>
                <h3>Please confirm by entering your full Email ID</h3>
                <p>{props.hiddenEmail}</p>
            </div>
            <div className={styles['container-body']}>
                <form onSubmit={formSubmitHandler}>
                    <InputBox 
                        label='Email Id'
                        name='emailId'
                        id='emailId'
                        type='email'
                        required 
                    />

                    <div className={formUtilityStyles['form-actions']}>
                        <Button
                            type='submit'
                            className='default'
                        >
                            Verify Email
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    );
}

function BFEmailVerification(props) {
    const [currentVerificationState, setCurrentVerficationState] = useState('EMAIL_CROSS_VERIFICATION');
    const [compToReturn, setCompToReturn] = useState(null);

    function setEmailOTPVerificationState(){
        setCurrentVerficationState('EMAIL_OTP_VERIFICATION');
    }

    useEffect(() => {
        switch (currentVerificationState) {
            case 'EMAIL_CROSS_VERIFICATION':
                setCompToReturn(
                    <EmailCrossVerification {...props} setEmailOTPVerificationState={setEmailOTPVerificationState} />
                );
            break;
                
            case 'EMAIL_OTP_VERIFICATION':
                setCompToReturn(
                    <EmailOTPVerification {...props} />
                );  
            break;
        
            default:
                props.setAlternateHeaderInfo({ header: 'State Error', body: 'No know state selected' })
                props.setDisplayLoader(false);
                setCompToReturn(<></>)
            break;
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentVerificationState])

    return compToReturn !== null && compToReturn;
}

export default BFEmailVerification;