// Functional Imports
import { useContext } from 'react';
import ErrorModalContext from '../../../../context/error-modal-context/error-modal-context';
import { isEmpty } from '../../../../Utility/Utility';

// Components Import
import AddressInput, { getAddressDataFromFormByName } from '../../../UI/AddressInput/AddressInput';
import Button from '../../../UI/Button/Button';

// Styled Imports
import formUtilityStyles from '../../BookingForm.module.css';


const { 'form-actions' : formActionsCN } = formUtilityStyles;



function BFPage4(props) {

    const errorModalCtx = useContext(ErrorModalContext);

    function handelViewChange(eventOrState){ 
        const viewState = eventOrState?.target?.getAttribute('data-change-state-to') || eventOrState;
        props.changeView(viewState);
    }

    async function formSubmitHandler(event){
        event.preventDefault();

        const presentAddress = getAddressDataFromFormByName(event, 'presentAddress');
        
        if( isEmpty( presentAddress.addressLine1 ) ){
            errorModalCtx.showError('Invalid Input!', 'Please enter a valid line1 address');
            return; 
        }

        if( isEmpty( presentAddress.country ) ){
            errorModalCtx.showError('Invalid Input!', 'Please enter a valid country');
            return; 
        }

        if( isEmpty( presentAddress.state ) ){
            errorModalCtx.showError('Invalid Input!', 'Please enter a valid state');
            return; 
        }

        if( isEmpty( presentAddress.city ) ){
            errorModalCtx.showError('Invalid Input!', 'Please enter a valid city');
            return; 
        }

        if( isEmpty( presentAddress.pincode ) ){
            errorModalCtx.showError('Invalid Input!', 'Please enter a valid pincode');
            return; 
        }

        errorModalCtx.clearCompErrorList();

        const payLoad = { presentAddress };

        if(await props.formSubmitHandler(payLoad)) handelViewChange('NEXT')
    }

    return (
        <>
            <form onSubmit={formSubmitHandler}>

                <AddressInput
                    label='Present Address'
                    name='presentAddress'
                    id='presentAddress'
                    value={props.filledValues.presentAddress}
                    required
                />

                

                <div className={formActionsCN}>
                    <Button
                        type='button'
                        className='negative'
                        onClick={handelViewChange}
                        dataAttributes={{'data-change-state-to': 'PREVIOUS'}}
                    >
                        <i className="lni lni-angle-double-left" data-change-state-to='PREVIOUS'></i>
                        Previous
                    </Button>
                    <Button
                        type='submit'
                        className='default'
                    >
                        Next
                        <i className="lni lni-angle-double-right" ></i>
                    </Button>
                </div>
            </form>
        </>
    );
}

export default BFPage4;