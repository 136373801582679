// Functional Imports
import React from 'react';
import ReactDOM from 'react-dom'

// Styles & Assets Import
import './ShowModal.css';
import alert from '../../../Assets/Images/alert.png';

function Backdrop(props){
    return <div className={'backdrop'} onClick={props.closeModal}></div>;
}

function ErrorModal(props){
    return (
        <div className="modal">
            <img src={alert} alt='Alert Logo' width="44" height="38" />
            <span className="title">{props.title}</span>
            <p>{props.message}</p>
            <div className="button" onClick={props.closeModal}>{(!!props.buttonName)?props.buttonName:'Dismiss'}</div>
        </div>
    )
}

function ShowModal(props){


    function closeModal(){
        props.closeModal();
    }

    const modal = ReactDOM.createPortal(
        <>
            <Backdrop closeModal={closeModal} />
            <div className='modal-container'>
                <ErrorModal {...props} closeModal={closeModal} />
            </div>
        </>,
        document.getElementById('modal')
    );

    return modal;
}

export default ShowModal;