import styles from './Container.module.css';

function Container( props ){

    return (
        <div className={`${styles.container} ${(props.className)? props.className : ''}`}>
            <div className={`${styles['container-div']}`}>
                {props.children}
            </div>
        </div>
    );
}

export default Container;